import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { FullscreenControl } from 'react-leaflet-fullscreen';
import "react-leaflet-fullscreen/styles.css";


const OrdersPolygonMap = (props) => {
    const [geoJsonData, setGeoJsonData] = useState(null);
    const [belgiumBoundary, setBelgiumBoundary] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const subMunicipalitySlug = props.currentSubmunicipalitySlug;
            const subscribedMunicipalities = props.subscribedMunicipalities;
            const subscribedProvinces = props.subscribedProvinces;
            const subscribedRegions = props.subscribedRegions;
            const excludedMunicipalities = props.excludedMunicipalities;

            if (subMunicipalitySlug) await fetchAndSetGeoJsonData(subMunicipalitySlug, subscribedMunicipalities, subscribedProvinces, subscribedRegions, excludedMunicipalities);
            await fetchBelgiumBoundary(); // Fetch Belgium boundary data
        };

        fetchData();
    }, [props.currentSubmunicipalityId, props.subscribedMunicipalities, props.subscribedProvinces, props.subscribedRegions, props.excludedMunicipalities, props.subscriptionType]);

    const getToken = () => {
        let token = localStorage.getItem("token");
        if (!token) {
            token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
        }
        return token;
    };

    const fetchAndSetGeoJsonData = async (subMunicipalitySlug, subscribedMunicipalities, subscribedProvinces, subscribedRegions, excludedMunicipalities) => {
        let loadingTimeout;

        try {
            // Start a timer to set loading state after 1 second
            const loadingPromise = new Promise((resolve) => {
                loadingTimeout = setTimeout(() => {
                    setLoading(true);
                    resolve();
                }, 1000);
            });

            // Fetch the data
            const fetchPromise = fetch(
                `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/get/polygons-from-subscription-types/?current_submunicipality_slug=${subMunicipalitySlug}&subscription_type_and_level=${props.subscriptionType}&subscribed_municipalities=${subscribedMunicipalities ?? ""}&subscribed_provinces=${subscribedProvinces ?? ""}&subscribed_regions=${subscribedRegions ?? ""}&excluded_municipalities=${excludedMunicipalities ?? ""}`,
                {
                    headers: {
                        Authorization: `Token ${getToken()}`,
                    }
                }
            );

            // Wait for either the fetch or the timeout (whichever comes first)
            await Promise.race([loadingPromise, fetchPromise]);

            // Clear the loading timeout if the fetch resolves before 1 second
            clearTimeout(loadingTimeout);

            // Continue with the fetch
            const response = await fetchPromise;

            // Set loading to false if it was set to true
            setLoading(false);

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }

            const data = await response.json();

            // Construct GeoJSON FeatureCollection
            const geoJsonFeatures = data.municipalities.map(municipalityData => {
                let geometry = null;
                try {
                    geometry = JSON.parse(municipalityData.municipality.geometry);
                } catch (e) {
                    console.error('Error parsing geometry:', e);
                    return null;
                }

                return {
                    type: "Feature",
                    geometry: geometry,
                    properties: {
                        id: municipalityData.municipality.id,
                        name: municipalityData.municipality.name_nl,
                        depth: municipalityData.depth || 0,
                    }
                };
            }).filter(feature => feature !== null);

            const geoJsonData = {
                type: "FeatureCollection",
                features: geoJsonFeatures,
            };

            setGeoJsonData(geoJsonData);
        } catch (error) {
            console.error('Error fetching GeoJSON data:', error);
        } finally {
            // Ensure loading is set to false in case of any errors
            clearTimeout(loadingTimeout);
            setLoading(false);
        }
    };


    const fetchBelgiumBoundary = async () => {
        try {
            const response = await fetch('https://o-sn.be/kml/belgium/country.min.json');
            if (!response.ok) {
                throw new Error(`Failed to fetch Belgium boundary: ${response.status}`);
            }
            const data = await response.json();
            setBelgiumBoundary(data);
        } catch (error) {
            console.error('Error fetching Belgium boundary:', error);
        }
    };

    const onEachFeature = (feature, layer) => {
        // Use `depth` directly from `feature.properties.depth`
        const depth = feature.properties.depth;

        // Use the color function with a fallback to 'orange' for undefined or null depths
        const fillColor = depth === null || depth === undefined ? 'orange' : getColorForDepth(depth);

        layer.setStyle({
            fillColor: fillColor,
            color: 'black',
            weight: 0.8,
        });
    };


    const getColorForDepth = (depth) => {
        if (depth === null || depth === undefined) return 'darkorange';
        const colors = [
            'darkorange', 'deepskyblue', 'crimson', 'limegreen', 'mediumvioletred',
            'gold', 'deepskyblue', 'magenta', 'lime', 'hotpink', 'teal', 'fuchsia',
            'chocolate', 'coral', 'mediumseagreen', 'orange', 'cyan', 'springgreen',
            'dodgerblue', 'darkorchid', 'firebrick', 'yellow', 'mediumturquoise',
            'royalblue', 'tomato', 'orangered', 'chartreuse', 'turquoise', 'sandybrown',
            'violet', 'darkcyan', 'lawngreen', 'mediumslateblue'
        ];

        return depth >= 0 && depth < colors.length ? colors[depth] : 'grey';
    };

    const MapWrapper = ({ children }) => {
        const map = useMap();

        useEffect(() => {
            if (geoJsonData) {
                const bounds = L.geoJSON(geoJsonData).getBounds();
                map.fitBounds(bounds);
            }
        }, [geoJsonData, map]);

        return <>{children}</>;
    };

    return (
        // if loading show loading spinner (loading.gif) from public folder on top right
        <div>
            {loading && <div style={{ textAlign: 'right' }}>
                <img src="/loading.gif" alt="Loading..." width={140} height='auto' />
            </div>}
            <MapContainer center={[50.8503, 4.3517]} zoom={7} style={{ height: '800px', width: '100%' }}>
                <FullscreenControl position="topright" />
                <MapWrapper>

                    {/* <TileLayer url="https://a.tile.openstreetmap.org/{z}/{x}/{y}.png" /> */}
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_only_labels/{z}/{x}/{y}.png" />
                    {belgiumBoundary && (
                        <GeoJSON
                            data={belgiumBoundary}
                            style={() => ({
                                color: 'black',
                                weight: 1,
                                fillOpacity: 0 // Transparent fill
                            })}
                        />
                    )}
                    {geoJsonData && <GeoJSON data={geoJsonData} onEachFeature={onEachFeature} />}
                </MapWrapper>
            </MapContainer>
        </div>
    );
};

export default OrdersPolygonMap;
