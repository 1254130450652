import { OsnSelect, OsnInputText, OsnInputDate, Button } from "@identitybuilding/idb-react-ui-elements";
import React, { useEffect, useState, useRef } from "react";
import SignatureCanvas from 'react-signature-canvas'
import Icon from "@identitybuilding/idb-react-iconlib";
import { openOrder } from "../actions/GeneralAction";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { updateCurrentEstablishment, updateLang, updateNav } from "../actions/GeneralAction";

import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import 'moment/locale/nl'; // Import specific locales as needed
import 'moment/locale/fr';
import 'moment/locale/de';
// DualListbox for packs and products
import DualListbox from 'react-dual-listbox';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import OrdersPolygonMap from "./orders/OrdersPolygonMap";
import _ from 'lodash';

function useForceUpdate() {
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here 
    // is better than directly setting `value + 1`
}

const createNotification = (type, message) => {
    if (type === "info") {
        NotificationManager.info(message);
    } else if (type === "success") {
        NotificationManager.success(message);
    } else if (type === "warning") {
        NotificationManager.warning(message);
    } else if (type === "error") {
        NotificationManager.error(message);
    }
};

const OrderModule = (props) => {
    const signatureCanvasRef = useRef(null);
    let signerRef = ""
    let parafRef = ""

    const dispatch = useDispatch();
    const history = useHistory();

    const [levelNumber, setLevelNumber] = useState(3);

    const current_establishment = useSelector((state) => state.general.current_establishment);
    const lang = useSelector((state) => state.general.lang);
    const translate = useSelector((state) => state.general.translate);
    const forceUpdate = useForceUpdate();

    const [storeType, setStoreType] = useState([
        { id: 0, name: 'OTHER', active: true, },
        { id: 1, name: 'RETAIL', active: false, },
    ])

    const [walletType, setWalletType] = useState([
        { id: 0, name: 'FACEBOOK', active: false, },
        { id: 1, name: 'INSTAGRAM', active: false, },
        { id: 2, name: 'GOOGLE BUSINESS', active: false, },
    ])

    const [retailTypes, setRetailTypes] = useState([
        { id: 0, name: 'SELECT', active: true, },
        { id: 1, name: 'DELHAIZE SUPERMARKTEN', active: false, },
        { id: 2, name: 'AD DELHAIZE', active: false, },
        { id: 3, name: 'PROXY DELHAIZE', active: false, },
        { id: 5, name: 'SHOP & GO DELHAIZE', active: false, },
        { id: 6, name: 'LOUIS DELHAIZE GROUP', active: false, },
        { id: 7, name: 'BUURTSLAGERS DELHAIZE', active: false, },
        { id: 8, name: 'ALBERT HEIJN', active: false, },
        { id: 9, name: 'CARREFOUR EXPRESS ORANGE', active: false, },
        { id: 10, name: 'CARREFOUR EXPRESS', active: false, },
        { id: 11, name: 'CARREFOUR MARKET', active: false, },
        { id: 12, name: 'CARREFOUR HYPERMARKET', active: false, },
        { id: 13, name: 'SPAR COLRUYT', active: false, },
        { id: 14, name: 'SPAR', active: false, },
        { id: 15, name: 'ALVO', active: false, },
        { id: 16, name: 'INTERMARCHÉ', active: false, },
        { id: 17, name: 'SMATCH', active: false, },
        { id: 18, name: 'MATCH', active: false, },
        { id: 19, name: 'MATCH FOOD & MORE', active: false, },
        { id: 20, name: 'OTHER', active: false, },
    ])

    const [paymentDate, setPaymentDate] = useState([
        { id: 0, name: translate('seven_days_after_receipt_of_invoice'), active: true, },
        { id: 1, name: translate('fourteen_days_after_receipt_of_invoice'), active: false, },
        { id: 2, name: translate('twentyone_days_after_receipt_of_invoice'), active: false, },
        { id: 3, name: translate('thirty_days_after_receipt_of_invoice'), active: false, },
        { id: 4, name: translate('thirty_days_end_of_month'), active: false, },
        { id: 7, name: translate('in_2_payments'), active: false, },
        { id: 6, name: translate('in_4_payments'), active: false, },
        { id: 5, name: translate('in_12_payments'), active: false, },
    ])
    const { est, ent } = useParams();
    const [regions, setRegions] = useState([])
    const [packs, setPacks] = useState([]);
    const [items, setItems] = useState([]);
    const [selectedPacks, setSelectedPacks] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [startDate, setStartDate] = useState("")
    const [data, setData] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [allOrdersLoaded, setAllOrdersLoaded] = useState(false);


    const [endDate, setEndDate] = useState("")
    const [publicationDate, setPublicationDate] = useState("")
    const [signDate, setSignDate] = useState("")
    const [signLocation, setSignLocation] = useState("")
    const [signature, setSignarture] = useState("")
    const [signer, setSigner] = useState("")
    const [paraf, setParaf] = useState("")
    const [tab, setTab] = useState(0)
    const [selectedMuni, setselectedMuni] = useState("")
    const [selectedsubMuni, setselectedsubMuni] = useState("")
    const [muniText, setMuniText] = useState("")

    const [typingTimeout, setTypingTimeout] = useState(null);
    const [searchResults, setSearchResults] = useState([]);
    const [wallets, setWallets] = useState([
        { type: "", value: "" }
    ]);
    const [type, _setType] = useState('list')
    const [error, setError] = useState('')
    const [orders, setOrders] = useState([]);
    const [order, setOrder] = useState({
        "id": "new",
        "establishment": "",
        "order_type": "",
        "store_type": "",
        "order_start": "",
        "order_end": "",
        "order_payment": translate('seven_days_after_receipt_of_invoice'),
        "extra_info": "",
        "order_publication": "",
        "invoice_email": "",
        "signature": "",
        "initials": "",
        "signer": "",
        "contact_signed_location": "",
        "contact_signed_date": "",
        "promotion_location": "",
        "packs": [],
        "items": [],
        "wallets": [],
        "region": {
            "id": "",
            "name": "",
            "level": ""
        },
        "subscription_type": "",
        "subscribed_municipalities": [],
        "subscribed_provinces": [],
        "subscribed_regions": [],
        "excluded_municipalities": [],
    });

    const [editable, setEditable] = useState(true)

    const [allMunicipalities, setAllMunicipalities] = useState([]);
    const [allRegions, setAllRegions] = useState([]);
    const [allProvinces, setAllProvinces] = useState([]);

    const [subscribedRegions, setSubscribedRegions] = useState([]);
    const [subscribedProvinces, setSubscribedProvinces] = useState([]);
    const [subscribedMunicipalities, setSubscribedMunicipalities] = useState([]);
    const [excludedMunicipalities, setExcludedMunicipalities] = useState([]);


    useEffect(() => {
        if (getGETParam('new') === 'true') {
            deleteGETParam('new')
        }
        fetchData();
    }, [])


    useEffect(() => {
        if (type === 'bon') {
            getGEOData();
            getRegions();
            getPacks();
            getItems();
        } else {
            getOrders();
        }
        // get url parameters
        const urlParams = new URLSearchParams(window.location.search);
        // if tab parameter is present, set the tab
        if (urlParams.get("tab")) {
            setTab(parseInt(urlParams.get("tab")));
        }
        // if order parameter is present, fetch the order details
        fetchOrderDetailsIfExistsInUrl();
    }, [type])

    const setType = (e) => {
        const urlParams = new URLSearchParams(window.location.search);
        const order = urlParams.get("order");
        const tab = urlParams.get("tab");
        const addingNew = urlParams.get("new");
        if (order && tab) {
            _setType('bon')
        }
        // else if (addingNew === 'true' && tab) {
        //     _setType('bon')
        // }
        else _setType(e)
    }

    const setOrderPromotionLocation = (location) => {
        orderHandler(location, 'promotion_location');
    }

    const fetchOrderDetailsIfExistsInUrl = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get("order")) {
            const orderId = urlParams.get("order");
            const orderResponse = orders.find((order) => order.id === orderId);
            setOrderHandler(await getOrderDetails(orderId));
        }
    }

    const fetchData = async () => {
        let token = localStorage.getItem("token");
        if (!token && getGETParam("order")) {
            token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
        }
        // if token is present or order id is given in the url
        if (token) {
            const headers = {
                "Content-Type": "application/json",
                // 'Authorization': `Token a0a40d840d5cad7525bf70bb6f4eceda7f0b64f0`,
                Authorization: `Token ${token}`,
            };
            axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/get/${est}/?lang=${lang}`, {
                headers: headers,
            })
                .then((res) => {
                    if (res.detail === "Invalid token.") {
                        history.push(`/${ent}/${est}/login?lang=${lang}`);
                    } else {
                        // set the data
                        if (res.data.enterprise_number !== ent) {
                            history.push(`/${res.data.enterprise_number}/${est}/?lang=${lang}`);
                            window.location.reload()
                        }
                        else {
                            dispatch(updateCurrentEstablishment(res.data));
                            setData(res.data);
                            setLoaded(true);
                        }
                    }

                    // if(json.path) history.push(`/${json.path}/`)
                })
                .catch((err) => {
                    console.log(err)
                    if (err.response.status === 401) {
                        history.push(`/${ent}/${est}/login?lang=${lang}`);
                        localStorage.setItem('token', '')
                    }
                    else if (err.response.status === 500) {
                        createNotification('error', 'Nog geen CI aangemaakt voor de Establishment')
                        setTimeout(() => {
                            history.push(`/`);
                        }, 4000);

                    }
                });
        } else {
            history.push(`/${ent}/${est}/login?lang=${lang}`);
        }
    };

    // the set defaults function is executed as soon as the establishment data is fetched. This adds defaults if values not present
    const setDefaults = (data) => {
        if (!order) return;
        if (!order.contact_signed_location) order.contact_signed_location = data.address?.municipality;

        setOrder(order);
    }


    const getOrders = () => {
        axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/api/orders/?lang=${lang}&search=${est}`)
            .then(async (res) => {
                if (res.data.results.length > 0) {
                    setOrders(res.data.results)
                    if (!getGETParam("order"))
                        setType('list')
                }
                setAllOrdersLoaded(true)

                //  else {
                //     setType('bon')
                // }
            })
    }

    const getOrderDetails = async (id) => {
        let token = localStorage.getItem("token");
        if (!token && getGETParam("order")) token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
        const headers = { "Content-Type": "application/json", Authorization: `Token ${token}` };
        const response = await axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/api/orders/${id}/`, {
            headers: headers,
        });
        return response.data;
    }

    const getGEOData = async () => {
        let token = localStorage.getItem("token");
        if (!token && getGETParam("order")) {
            token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
        }
        const headers = {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
        };
        const regionsUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/region/?lang=${lang}&fields=id,name&paginate=false`;
        const provincesUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/province/?lang=${lang}&fields=id,name&paginate=false`;
        const municipalitiesUrl = `${process.env.REACT_APP_BASE_MEDIA_URL}/core/new-api/geo/municipality/?lang=${lang}&fields=id,name_nl,name_fr,name_de,name_en&paginate=false`;
        const [regionsResponse, provincesResponse, municipalitiesResponse] = await Promise.all([
            axios.get(regionsUrl, { headers }),
            axios.get(provincesUrl, { headers }),
            axios.get(municipalitiesUrl, { headers }),
        ]);

        // need in form of label and value
        const formattedRegions = regionsResponse.data.map(region => ({ label: region.name, value: region.id }));
        const formattedProvinces = provincesResponse.data.map(province => ({ label: province.name, value: province.id }));
        const formattedMunicipalities = municipalitiesResponse.data.map(municipality => {
            const label = municipality.name_nl && municipality.name_fr ? `${municipality.name_nl} / ${municipality.name_fr}` : municipality.name_nl ? municipality.name_nl : municipality.name_fr;
            return { label: label, value: municipality.id }
        });

        setAllRegions(formattedRegions);
        setAllProvinces(formattedProvinces);
        setAllMunicipalities(formattedMunicipalities);



    }

    const getRegions = () => {
        axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/api/regions/`)
            .then((res) => {
                let copy = []
                res.data.results.map((item) => {
                    let newItem = {
                        'id': item.id,
                        'name': item.name === 'LOCAL' || item.name === 'PROVINCE' || item.name === "REGION" ? `${item.name} + ${item.level}` : item.name === 'COUNTRY' ? `${item.name}` : `${item.name} ${item.level}`,
                        'active': false
                    }
                    if (item.name === 'LEVEL' && item.level === 3) { newItem.active = true }
                    copy.push(newItem)
                })
                setRegions(copy)
            })
    }
    const getPacks = () => {
        axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/api/packs/`)
            .then((res) => {
                const formattedPacks = res.data.results.map(item => ({
                    id: item.id,
                    label: item.name, // or appropriate field for display
                    value: item.id,
                    selected: order.packs.some(pck => pck.id === item.id)
                }));
                setPacks(formattedPacks);
                setSelectedPacks(formattedPacks.filter(p => p.selected).map(p => p.value));
            })
    }

    const getItems = () => {
        axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/api/items/`)
            .then((res) => {
                const formattedItems = res.data.results.map(item => ({
                    id: item.id,
                    label: item[`name_${lang || "nl"}`] || item.name_fr || item.name_de || item.name_en, // or appropriate field for display
                    value: item.id,
                    selected: order.items.some(pck => pck.id === item.id)
                }));
                setItems(formattedItems);
                setSelectedItems(formattedItems.filter(p => p.selected).map(p => p.value));
            })
    }

    const getSubscriptionTypeNumber = () => {
        // order.region.name has a number in the string, extract it and return as a number type
        const number = order.region.name.match(/\d+/);
        return number ? parseInt(number[0]) : 0;
    }

    const getSubscriptionTypeName = () => {
        // only return the text in order.region.name
        return order.region.name.split(' ')[0].toLowerCase();
    }


    const handlePacksChange = (selected) => {
        setSelectedPacks(selected);
        orderHandler(selected, 'packs');
    };

    const handleItemsChange = (selected) => {
        setSelectedItems(selected);
        orderHandler(selected, 'items');
    };

    const handleSubscribedRegionsChange = (selected) => {
        const subscriptionTypeNumber = getSubscriptionTypeNumber();
        if (selected.length >= subscriptionTypeNumber + 1) {
            // show notification that the number of regions selected is equal to the subscription type number (in dutch)
            createNotification('warning', `Het aantal geselecteerde regio's is gelijk aan het aantal regio's dat is toegestaan voor dit abonnementstype.`);
            return;
        }
        setSubscribedRegions(selected);
        // update order with the selected regions
        orderHandler(selected, 'subscribed_regions');
    };

    const handleSubscribedProvincesChange = (selected) => {
        const subscriptionTypeNumber = getSubscriptionTypeNumber();
        if (selected.length >= subscriptionTypeNumber + 1) {
            // show notification that the number of provinces selected is equal to the subscription type number (in dutch)
            createNotification('warning', `Het aantal geselecteerde provincies is gelijk aan het aantal provincies dat is toegestaan voor dit abonnementstype.`);
            return;
        }
        console.log(subscriptionTypeNumber);
        setSubscribedProvinces(selected);
        orderHandler(selected, 'subscribed_provinces');
    };

    const handleSubscribedMunicipalitiesChange = (selected) => {
        const subscriptionTypeNumber = getSubscriptionTypeNumber();
        if (selected.length >= subscriptionTypeNumber + 1 && order.region.name.includes('LOCAL')) {
            // show notification that the number of municipalities selected is equal to the subscription type number (in dutch)
            createNotification('warning', `Het aantal geselecteerde gemeenten is gelijk aan het aantal gemeenten dat is toegestaan voor dit abonnementstype.`);
            return;
        }
        setSubscribedMunicipalities(selected);
        orderHandler(selected, 'subscribed_municipalities');
    };

    const handleExcludedMunicipalitiesChange = (selected) => {
        setExcludedMunicipalities(selected);
        orderHandler(selected, 'excluded_municipalities');
    };

    const validateSubscriptionCounts = () => {
        const requiredCount = getSubscriptionTypeNumber();


        const regionName = getSubscriptionTypeName().toLowerCase();
        if (regionName == "local") {
            const count = order.subscribed_municipalities.length;
            if (requiredCount != count) {
                createNotification(
                    'warning',
                    `Gelieve ${requiredCount} gemeentes te selecteren voor dit abonnementstype.`
                );
                return false; // Validation failed
            }
        }
        else if (regionName == "province") {
            const count = order.subscribed_provinces.length;
            if (requiredCount != count) {
                createNotification(
                    'warning',
                    `Gelieve ${requiredCount} provincies te selecteren voor dit abonnementstype.`
                );
                return false; // Validation failed
            }
        }
        else if (regionName == "region") {
            const count = order.subscribed_regions.length;
            if (requiredCount != count) {
                createNotification(
                    'warning',
                    `Gelieve ${requiredCount} regio's te selecteren voor dit abonnementstype.`
                );
                return false; // Validation failed
            }
        }
        return true;
    };


    const clearAllSubscriptionTypeModels = () => {
        // setSubscribedRegions([]);
        // setSubscribedProvinces([]);
        // setSubscribedMunicipalities([]);
        // setExcludedMunicipalities([]);

        const currentSubscriptionType = order.region.name;
        if (currentSubscriptionType.includes("LEVEL")) {
            orderHandler([], 'subscribed_regions');
            orderHandler([], 'subscribed_provinces');
        } else {
            orderHandler([], 'subscribed_regions');
            orderHandler([], 'subscribed_provinces');
            orderHandler([], 'subscribed_municipalities');
            orderHandler([], 'excluded_municipalities');
        }

    }



    // const searchHandler = (e) => {
    //     const value = e.target.value;

    //     // Clear any existing typing timeout
    //     if (typingTimeout) {
    //         clearTimeout(typingTimeout);
    //     }

    //     // Set a new timeout to make the request after 2 seconds
    //     const newTypingTimeout = setTimeout(() => {
    //         axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/geo/api/new/municipalities/?search=${value}&lang=${lang}`)
    //             .then((res) => {

    //                 setSearchResults(res.data.results)
    //             })
    //     }, 1000);

    //     setTypingTimeout(newTypingTimeout);
    // }

    const searchHandler = (e) => {
        const value = e.target.value;

        // Clear any existing typing timeout
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        let as_model = require(`../models/AutosuggestionLocationModel`).default;

        let stringFields = [
            `name_nl`,
            `name_fr`,
            `name_de`,
            `name_en`,
            `alt_name_nl`,
            `alt_name_fr`,
            `submunicipality_set.alt_name_fr`,
            `submunicipality_set.alt_name_nl`,
            `submunicipality_set.name_nl`,
            `submunicipality_set.name_fr`,
            `submunicipality_set.name_de`,
            `submunicipality_set.name_en`,
            `nis_code`,
            `nis_code_1970`,
            `nis_code_1977`,
            `submunicipality_set.nis_code_1970`,
            `submunicipality_set.nis_code_1977`,
        ];

        let numberFields = [
            "pkancode",
            "nis_code",
            "nis_code_1970",
            "nis_code_1977",
            "submunicipality_set.pkancode",
            "submunicipality_set.nis_code_1970",
            "submunicipality_set.nis_code_1977",
        ];

        let option = {};
        if (isNaN(value)) {
            option = option = {
                from: 0,
                size: 581,
                query: {
                    bool: {
                        should: [
                            {
                                query_string: {
                                    query: `\"${value}\"`,

                                    fields: stringFields,
                                    tie_breaker: 0.3,
                                }
                            },
                            {
                                query_string: {
                                    query: `*${value}*`,

                                    fields: stringFields,
                                    tie_breaker: 0.3,
                                }
                            },
                            {
                                query_string: {
                                    query: `${value}`,

                                    fields: stringFields,
                                    tie_breaker: 0.3,
                                }
                            }
                        ]
                    }

                },
                sort: [
                    // {
                    //   pkancode: {
                    //     order: "asc",
                    //   },
                    // },
                ],
            };
        } else {
            option = {
                size: 581,
                query: {
                    query_string: {
                        query: `${value}`,
                        fields: numberFields,
                        tie_breaker: 0.3,
                    },
                },
            };
        }


        // Set a new timeout to make the request after 2 seconds
        const newTypingTimeout = setTimeout(() => {

            axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL2}/municipalities/_search`,
                {
                    auth: {
                        username: `${process.env.REACT_APP_ESUSERNAME}`,
                        password: `${process.env.REACT_APP_ESPSW}`,
                    },
                    params: {
                        source_content_type: "application/json",
                        source: JSON.stringify(option),
                    },
                }
            ).then((res) => {
                let data = res.data.hits.hits;
                let results = as_model(data, lang, translate, value);
                setSearchResults(results)
            })


        }, 1000);

        setTypingTimeout(newTypingTimeout);
    }

    // Explaination: this function is used to set the height of the sections
    // and also to set the left position of the sections
    const setHeigt = (e) => {
        // if type is not list
        setTab(e)
        // set tab in url as well
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set("tab", e);
        history.push(window.location.pathname + "?" + urlParams.toString());
        setTimeout(() => {
            let sections = document.querySelector('.sections')
            let section = document.querySelector('.section.active')

            if (sections && sections.style) sections.style.height = `${section.clientHeight}px`
            if (e === 0) {
                sections.style.left = `${e}00%`
            }
            else {
                sections.style.left = `-${e}00%`

            }

        }, 100);

    }
    const walletHandler = (e, index, type) => {
        let copy = order
        if (type === 'type') { copy.wallets[index].service = e.name }
        else if (type === 'value') { copy.wallets[index].value = Number(e.target.value) }
        else if (type === 'delete') { copy.wallets.splice(index, 1) }
        else { copy.wallets.push({ service: "", value: "", id: "", order: order.id }) }
        setOrder(copy)
        forceUpdate()
    }

    const orderHandler = (e, type) => {
        let copy = order
        if (type === 'order_type' || type === 'store_type' || type === 'order_payment') {
            copy[type] = e.name
        }

        else if (type === "region") {
            const currentRegionName = order.region.name.toLowerCase().split(' ')[0];
            const newRegionName = e.name.toLowerCase().split(' ')[0];
            const prevSubscriptionType = getSubscriptionTypeNumber();
            const newSubscriptionType = parseInt(e.name.match(/\d+/)?.[0] || 0);
            debugger;
            if (currentRegionName == newRegionName && newSubscriptionType < prevSubscriptionType) clearAllSubscriptionTypeModels();
            copy.region = e;
            copy.subscription_type = convertRegionNameForPolygon(e.name);
        }
        else if (type === 'packs') {
            // Update the packs in the order based on the selected values
            copy.packs = packs.filter(pack => e.includes(pack.value));
        } else if (type === 'items') {
            // Update the items in the order based on the selected values
            copy.items = items.filter(item => e.includes(item.value));
        } else if (type === 'order_start') {
            copy[type] = e.target.value
            if (copy["order_end"] === "") {
                // Your original date string
                const dateString = copy[type];
                const initialDate = new Date(dateString);
                initialDate.setFullYear(initialDate.getFullYear() + 1);
                initialDate.setDate(initialDate.getDate() - 1);
                const year = initialDate.getFullYear();
                const month = String(initialDate.getMonth() + 1).padStart(2, "0");
                const day = String(initialDate.getDate()).padStart(2, "0");
                // const end_date_value = `${day}-${month}-${year}`;
                const end_date_value = `${year}-${month}-${day}`;
                copy["order_end"] = end_date_value

                // copy["order_end"] = resultDateString
            }
        }
        else if (type === "signature" || type === "signer" || type === "initials") {
            copy[type] = ""
        }
        else if (type === "extra_info") {
            copy[type] = e.target.value
        } else if (type == 'subscribed_regions') {
            copy[type] = e;
        } else if (type == 'subscribed_provinces') {
            copy[type] = e;
        } else if (type == 'subscribed_municipalities') {
            copy[type] = e;
        } else if (type == 'excluded_municipalities') {
            copy[type] = e;
        }
        else if (type == 'promotion_location') {
            copy[type] = e;
        }
        else {
            copy[type] = e.target.value
        }
        setOrder(copy)
        forceUpdate()
    }


    const renderImage = () => {
        try {
            try {
                return <img src={require(`../library/images/levels/${muniText.toLocaleLowerCase().replace('brussel (stad)', 'brussel-stad')}-l3.svg`)} />
            }
            catch {

                try {
                    return <img src={require(`../library/images/levels/${selectedsubMuni}-l3.svg`)} />
                }
                catch {
                    return <img src={require(`../library/images/levels/${selectedMuni}-l3.svg`)} />
                }
            }

        } catch (err) {
            return <img src={require(`../library/images/download.png`)} />
        }
    };

    const setOrderHandler = (res) => {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set("order", res.id);
        history
            .push(window.location.pathname + "?" + searchParams.toString());
        const urlTab = searchParams.get("tab");
        setHeigt(urlTab ? parseInt(urlTab) : 0)
        res.region.name = res.region.name === 'LOCAL' || res.region.name === 'PROVINCE' ? `${res.region.name} + ${res.region.level}` : res.region.name === 'REGION' || res.region.name === 'COUNTRY' ? `${res.region.name}` : `${res.region.name} ${res.region.level}`
        setOrder(res)

        try {
            if (res.promotion_location) { setMuniText(res.promotion_location) }
            else { setMuniText(res.establishment.address.municipality.toLowerCase()) }

        }
        catch { }
        try {
            console.log(res.establishment.address)
            setselectedMuni(res.establishment.address.municipality.toLowerCase())
        }
        catch { }
        try {
            setselectedsubMuni(res.establishment.address.sub_municipality.toLowerCase())
        }
        catch { }

        if (signatureCanvasRef.current) {
            signatureCanvasRef.current.fromDataURL(`data:image/png;base64,${res.signature}`);
        }
        setType('bon')
        if (res.signer !== '' && res.signarture !== '' && res.initials !== '') {
            setEditable(false)
        }
        if (!current_establishment || !current_establishment.address) return;
        setselectedMuni(current_establishment.address?.slug)
        setselectedsubMuni(current_establishment.address?.sub_slug)
    }

    const updateHandler = async (res) => {
        let copy = order
        copy.initials = paraf
        copy.signer = signer
        copy.signature = signature
        copy.promotion_location = muniText
        if (copy.establishment === "") {
            copy.establishment = { 'number': current_establishment.establishment.number }
        }
        setOrder(copy)
        let token = localStorage.getItem("token");
        if (!token && getGETParam("order")) token = process.env.REACT_APP_DEFAULT_OVERRIDE_TOKEN;
        const headers = { "Content-Type": "application/json", Authorization: `Token ${token}` };
        await axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/update/${order.id}/`, {
            headers: headers,
            data: copy,
            token: token,
        }).then((res) => {
            setType('list')
            resetHandler()
        })

    }

    const resetHandler = () => {
        // unset the tab and order parameters in the url
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("tab");
        urlParams.delete("order");
        urlParams.delete("new");
        history
            .push(window.location.pathname + "?" + urlParams.toString());
        setType('list')
        setHeigt(0)
        setOrder({
            "id": "new",
            "establishment": "",
            "order_type": "",
            "store_type": "",
            "order_start": "",
            "order_end": "",
            "order_payment": translate('seven_days_after_receipt_of_invoice'),
            "extra_info": "",
            "order_publication": "",
            "invoice_email": "",
            "signature": "",
            "initials": "",
            "signer": "",
            "contact_signed_location": "",
            "contact_signed_date": "",
            "packs": [],
            "items": [],
            "wallets": [],
            "region": {
                "id": "",
                "name": "",
                "level": ""
            },
            "subscription_type": "",
            "subscribed_municipalities": [],
            "subscribed_provinces": [],
            "subscribed_regions": [],
            "excluded_municipalities": [],
        })
        setEditable(true)
        setselectedMuni(current_establishment.address?.slug)
        setselectedsubMuni(current_establishment.address?.sub_slug)


    }

    const isProperEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    }

    const nextCheck = () => {
        if (editable) {
            if (tab === 0) {

                // initial values if empty
                if (order.order_start === "") {
                    order.order_start = new Date().toISOString().split('T')[0];
                    // createNotification("error", "Vul aanvang in"), setError('order_start'); 
                }
                if (order.order_end === "") {
                    order.order_end = new Date(new Date().setDate(new Date().getDate() + 365)).toISOString().split('T')[0];
                    // createNotification("error", "Vul Einde in"), setError('order_end') 
                }


                // validation checks
                if (order.order_type === "") { createNotification("error", "Kies een type"), setError('order_type') }
                else if (order.order_type !== "OTHER" && order.store_type === "") { createNotification("error", "Kies een winkel type"), setError('store_type') }
                else if (order.order_payment === "") { createNotification("error", "Vul betaling in"), setError('order_payment') }
                else if (order.order_publication === "") { createNotification("error", "Vul Publicatie in"), setError('order_publication') }
                else if (order.invoice_email === "") { createNotification("error", "Vul een facturatie e-mail in"), setError('invoice_email') }
                else if (!isProperEmail(order.invoice_email)) { createNotification("error", "Vul een geldig facturatie e-mail in"), setError('invoice_email') }
                else { setHeigt(tab + 1) }
            }
            if (tab === 1) {
                if (!validateSubscriptionCounts()) return;
                if (order.region.id === "") {
                    createNotification("error", "Kies een level");
                    setError('region');
                } else {
                    setHeigt(tab + 1);
                }
            }
            if (tab === 2) {

                // initial values if empty
                if (order.contact_signed_date === "") {
                    order.contact_signed_date = new Date().toISOString().split('T')[0];
                    // createNotification("error", "Vul een tekendatum in"), setError('contact_signed_date');
                }

                if (order.contact_signed_location === "") {
                    order.contact_signed_location = data.address.municipality; // if empty set the establishment's municipality
                }

                // validation checks
                if (order.contact_signed_date === "") {
                    createNotification("error", "Vul een tekendatum in"), setError('contact_signed_date');
                }
                else if (order.contact_signed_location === "") {
                    createNotification("error", "Vul een tekenlocatie in"), setError('contact_signed_location');
                }
                else {
                    setHeigt(tab + 1);
                }
            }

        }

        else {
            setHeigt(tab + 1)
        }
    }

    const setNewMuni = () => {
        setselectedMuni(current_establishment.address.municipality.toLowerCase())
        if (current_establishment.address.municipality.toLowerCase() === 'gent' || current_establishment.address.municipality.toLowerCase() === 'antwerpen') {
            setMuniText(current_establishment.address.sub_municipality.toLowerCase())
            setselectedsubMuni(current_establishment.address.sub_municipality.toLowerCase())
        }
        else {
            setMuniText(current_establishment.address.municipality.toLowerCase())

        }
    }

    const closeOrdersModule = () => {
        history.push(`/${ent}/${est}/?lang=${lang}`);
    }

    const formatDate = (date) => {
        const momentDate = moment(date, 'YYYY-MM-DD HH:mm:ss'); // Specify format if needed

        const urlParams = new URLSearchParams(window.location.search);
        const lang = urlParams.get("lang") || 'nl';

        console.log(lang);
        momentDate.locale(lang);

        return momentDate.format('ll'); // 'lll' includes both date and time
    };

    const setGETParam = (key, value) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set(key, value);
        history
            .push(window.location.pathname + "?" + urlParams.toString());
    }

    const getGETParam = (key) => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(key);
    }

    const deleteGETParam = (key) => {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete(key);
        history
            .push(window.location.pathname + "?" + urlParams.toString());
    }

    const convertRegionNameForPolygon = (name) => {
        // Convert the region name to the format used in the polygon component
        // example: LOCAL + 3 => local_3, LEVEL 2 => level_2, PROVINCE + 0 => province_0, REGION => region, COUNTRY => country
        // please remember there can only be one underscore between the type and level
        return name.toLowerCase().replace(/ /, "_").replace("+", "").replace(/ /, "");
    }


    return (
        <div className="orderBlock">
            <NotificationContainer />
            {type === 'list' ?
                <div className="content list">
                    <div className="sections">
                        <Icon name="Close" className='close' onClick={() => closeOrdersModule()} />
                        <div className="section active">
                            <h2><span>Bestelbonnen</span></h2>
                            <div className="section-content">
                                {loaded && allOrdersLoaded && <div className="newbutton" data-cy="newbutton" onClick={() => { setType('bon'), setHeigt(0), setNewMuni(); setGETParam('new', 'true') }}>{translate('new')}</div>}
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>{translate('entrepreneurs_number')}</th>
                                            <th>{translate('enterprise_name')}</th>
                                            <th>{translate('establishment_number')}</th>
                                            <th>{translate('commercial_name')}</th>
                                            <th>{translate('value')}</th>
                                            <th>{translate('Date')}</th>
                                            <th>PDF</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {orders.map((res, index) => (
                                            <tr key={index}>
                                                <td className="id" onClick={() => { setOrderHandler(res) }}>{res.id}</td>
                                                <td>{res.establishment.from_enterprise.number}</td>
                                                <td>{res.establishment.from_enterprise.official_name}</td>
                                                <td>{res.establishment.number}</td>
                                                <td>{res.establishment.name}</td>
                                                <td>€ {res.end_value}</td>
                                                <td>{formatDate(res.order_start)}</td>


                                                <td>
                                                    <span className="buttons">
                                                        <a href={`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/${res.id}/download/pdf?lang=nl`} target="_blank" rel="noopener noreferer" className="button">PDF NL</a>
                                                        <a href={`${process.env.REACT_APP_BASE_MEDIA_URL}/orders/${res.id}/download/pdf?lang=fr`} target="_blank" rel="noopener noreferer" className="button">PDF FR</a>
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>

                            </div>

                        </div>
                    </div>
                </div>
                :
                <div className="content bon">
                    <Icon name="ArrowLeft" data-cy="reset-button" className='back' onClick={() => { resetHandler() }} />
                    <Icon name="Close" data-cy="close-button" className='close' onClick={() => closeOrdersModule()} />
                    <div className="tabs">
                        <div className={`tab ${tab === 0 ? 'active' : ''}`} onClick={() => { tab < 0 ? nextCheck(0) : setHeigt(0) }}>01 <span className="tabtitle">&nbsp;{lang == "nl" ? "Algemene informatie" : translate('general_info')}</span></div>
                        <div className={`tab ${tab === 1 ? 'active' : ''}`} onClick={() => { tab < 1 ? nextCheck(1) : setHeigt(1) }}>02 <span className="tabtitle">&nbsp;{translate('packs_and_products')}</span></div>
                        <div className={`tab ${tab === 2 ? 'active' : ''}`} onClick={() => { tab < 2 ? nextCheck(2) : setHeigt(2) }}>03 <span className="tabtitle">&nbsp;{translate('agreement')}</span></div>
                        <div className={`tab ${tab === 3 ? 'active' : ''}`} onClick={() => { tab < 3 ? nextCheck(3) : setHeigt(3) }}>04 <span className="tabtitle">&nbsp;{translate('conditions')}</span></div>
                    </div>
                    <div className={`sections ${editable ? '' : 'disable'}`}>
                        <div className={`section ${tab === 0 ? 'active' : ''}`}>
                            <h2><span>01</span>{lang == "nl" ? "Algemene informatie" : translate('general_info')}</h2>
                            <div className="section-content">
                                <h5>{translate('annual_publication')} <br /> {translate('contact_expires_after')}</h5>
                                {/* <p>{translate('cancellation_of_the_osn_business_page')}</p> */}
                                <p dangerouslySetInnerHTML={{ __html: translate('cancellation_of_the_osn_business_page') }} />

                                <div className="inputs">
                                    <div className="inputDiv" data-cy="order_type">
                                        <label className="selectLabel">Type</label>
                                        <OsnSelect
                                            className="order-select"
                                            onChange={(e) => { orderHandler(e, 'order_type') }}
                                            // active={storeType.filter(item => item.active === true)[0].name}
                                            active={order.order_type}
                                            options={storeType}
                                            disabled={editable ? false : true}
                                        />
                                    </div>

                                    {order.order_type === 'RETAIL' && (
                                        <div className="inputDiv">
                                            <label className="selectLabel">{translate('supermarket_type')}</label>
                                            <OsnSelect
                                                className="order-select"
                                                active={order.store_type}
                                                onChange={(e) => { orderHandler(e, 'store_type') }}
                                                options={retailTypes}
                                                disabled={editable ? false : true}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className="inputs">
                                    <OsnInputDate
                                        title={translate('start')}
                                        // defaultValue={order.order_start}
                                        defaultValue={order.order_start || new Date().toISOString().split('T')[0]}
                                        onChangeDate={(e) => { "" }}
                                        // onBlurDate={(e) => { console.log('ok') }}
                                        onBlurDate={(e) => { orderHandler(e, 'order_start') }}
                                        disabled={editable ? false : true}
                                    />
                                    <OsnInputDate
                                        title={translate('end')}
                                        // value_date={order.order_end}
                                        defaultValue={order.order_end || new Date(new Date().setDate(new Date().getDate() + 364)).toISOString().split('T')[0]}
                                        onChangeDate={(e) => { orderHandler(e, 'order_end') }}
                                        disabled={editable ? false : true}
                                        className="order_end"
                                    />
                                </div>
                                <div className="inputs" data-cy="order_publication">
                                    <div className="inputDiv">
                                        <label className="selectLabel">{translate('payment')}</label>
                                        <OsnSelect
                                            data-cy="payment-option"
                                            className="order-select"
                                            active={order.order_payment}
                                            onChange={(e) => { orderHandler(e, 'order_payment') }}
                                            options={paymentDate}
                                            disabled={editable ? false : true}
                                        />
                                    </div>
                                    <OsnInputDate
                                        title={translate('publication')}
                                        defaultValue={order.order_publication}
                                        onChangeDate={(e) => { orderHandler(e, 'order_publication') }}
                                        onBlurDate={(e) => { orderHandler(e, 'order_publication') }}
                                        data-cy="date-picker"
                                        disabled={editable ? false : true}
                                    />
                                </div>
                                <div className="inputs" data-cy="invoice_email">
                                    <OsnInputText disabled={editable ? false : true} size="M" title={translate('billing_email')} defaultValue={order.invoice_email} onChange={(e) => orderHandler(e, 'invoice_email')} />
                                </div>

                            </div>
                        </div>
                        <div className={`section ${tab === 1 ? 'active' : ''}`}>
                            <h2 className="second">
                                <div className="title-item"><span>02</span>{translate('packs_and_products')}</div>
                                <div className="title-item" data-cy="sub-type">
                                    {regions.length > 0 &&
                                        <OsnSelect
                                            className="order-select"
                                            onChange={(e) => { orderHandler(e, 'region'); }}
                                            active={order.region.name}
                                            options={regions}
                                            disabled={editable ? false : true}
                                        />
                                    }
                                    <OsnInputText className="extra_info" defaultValue={order.extra_info} title={translate('extra_info')} onChange={(e) => orderHandler(e, 'extra_info')} />
                                </div>
                                <div className="title-item" style={{ zIndex: 10000 }}>
                                    <h6>{translate('in_which_region_can_customers_find_you')}</h6>
                                    {/* <h5>{muniText}</h5> */}
                                    <OsnInputText disabled={editable ? false : true} title={translate('search_municipality_ci')} value={_.capitalize(muniText)} onChange={(e) => { setMuniText(e.target.value), searchHandler(e) }} />
                                    <div className={`autosuggestion ${searchResults.length > 0 ? 'active' : ''}`}>
                                        {searchResults.map((result, idx) => (
                                            <div key={idx} onClick={() => { setOrderPromotionLocation(result.slug), setMuniText(result.slug), setselectedsubMuni(result?.slug), setselectedMuni(result?.slug), setSearchResults([]), setHeigt(tab) }}>{result.text}</div>
                                            /* {result.children.map((child, idx) => (
                                                <div key={idx} onClick={() => { setselectedsubMuni(child?.slug), setselectedMuni(child?.slug), setSearchResults([]), setHeigt(tab) }}>{child.pkancode} - {child.name}</div>
                                            ))} */
                                        ))}
                                    </div>
                                </div>
                            </h2>
                            <div className="section-content">
                                <div className="pack-container" data-cy="UpperElements">
                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: ' space-between', width: '70%' }}>
                                        {order.region.name.toLowerCase().includes('region') && <div>
                                            <h4 style={{ color: 'black' }}>Geabonneerde regio's</h4>
                                            <DualListbox
                                                options={allRegions}
                                                selected={order.subscribed_regions}
                                                onChange={handleSubscribedRegionsChange}
                                                disabled={!editable}
                                                canFilter
                                                showOrderButtons
                                            />
                                        </div>}
                                        {order.region.name.toLowerCase().includes('province') && <div>
                                            <h4 style={{ color: 'black' }}>Geabonneerde provincies</h4>
                                            <DualListbox
                                                options={allProvinces}
                                                selected={order.subscribed_provinces}
                                                onChange={handleSubscribedProvincesChange}
                                                disabled={!editable}
                                                canFilter
                                                showOrderButtons
                                            />
                                        </div>}
                                        {(order.region.name.toLowerCase().includes('local') || order.region.name.toLowerCase().includes('level')) && <div>
                                            <h4 style={{ color: 'black' }}>Geabonneerde gemeenten</h4>
                                            <DualListbox
                                                options={allMunicipalities}
                                                selected={order.subscribed_municipalities}
                                                onChange={handleSubscribedMunicipalitiesChange}
                                                disabled={!editable}
                                                canFilter
                                                showOrderButtons
                                            />
                                        </div>
                                        }
                                        {order.region.name != "" &&
                                            <div>
                                                <h4 style={{ color: 'black' }}>Uitgesloten gemeenten</h4>
                                                <DualListbox
                                                    options={allMunicipalities}
                                                    selected={order.excluded_municipalities}
                                                    onChange={handleExcludedMunicipalitiesChange}
                                                    disabled={!editable}
                                                    canFilter
                                                    showOrderButtons
                                                />
                                            </div>
                                        }
                                        <h4 style={{ color: 'black' }}>
                                            {translate('packs')}</h4>
                                        <DualListbox
                                            options={packs}
                                            selected={selectedPacks}
                                            onChange={handlePacksChange}
                                            disabled={!editable}
                                            canFilter
                                            showOrderButtons
                                        />
                                        <h4 style={{ color: 'black' }}>
                                            {translate('products')}</h4>
                                        <DualListbox
                                            options={items}
                                            selected={selectedItems}
                                            onChange={handleItemsChange}
                                            disabled={!editable}
                                            canFilter
                                            showOrderButtons
                                        />
                                        <h4 style={{ paddingBottom: '1px', display: 'block', color: 'black' }}>{translate('wallets')}</h4>
                                        <div className="wallets" style={{ padding: '8px' }}>
                                            {order.wallets.map((wallet, id) => (
                                                <div className="wallet" key={id}>
                                                    <OsnSelect
                                                        className="order-select"
                                                        onChange={(e) => { walletHandler(e, id, 'type') }}
                                                        active={wallet.service}
                                                        options={walletType}
                                                        disabled={!editable}
                                                    />
                                                    <OsnInputText
                                                        title={translate('value')}
                                                        disabled={!editable}
                                                        value={wallet.value}
                                                        type="number"
                                                        onChange={(e) => walletHandler(e, id, 'value')}
                                                    />
                                                    <h6 style={{ cursor: 'pointer' }} onClick={(e) => walletHandler(e, id, 'delete')}>{translate('delete')}</h6>
                                                </div>
                                            ))}
                                            <Button
                                                borderColor="main"
                                                text={translate('meer_toevoegen')}
                                                txtColor="sub"
                                                type="sub"
                                                size="S"
                                                onClick={(e) => { walletHandler(e, 0, 'add'), setHeigt(tab) }}
                                            />
                                        </div>
                                    </div>

                                    <div className="pack-item image">
                                        {/* <figure>
                                            {renderImage()}
                                        </figure> */}
                                        <OrdersPolygonMap key={order?.promotion_location || selectedsubMuni || selectedMuni || data.address?.sub_slug} currentSubmunicipalitySlug={order.promotion_location?.toLowerCase()?.replace(/ /g, "-") || selectedsubMuni || selectedMuni || data.address?.sub_slug} subscriptionType={order.subscription_type.toLowerCase()} subscribedRegions={order.subscribed_regions ?? []} subscribedMunicipalities={order.subscribed_municipalities ?? []} subscribedProvinces={order.subscribed_provinces ?? []} excludedMunicipalities={order.excluded_municipalities ?? []} />
                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className={`section ${tab === 2 ? 'active' : ''}`}>
                            <h2><span>03</span>{translate('agreement')}</h2>
                            <div className="section-content">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan={4}><div dangerouslySetInnerHTML={{ __html: translate('digitally_created_and_approved_by_both_parties') }} /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td colSpan={2}><span>{translate('at')}: <OsnInputText placeholder="..." disabled={editable ? false : true} defaultValue={order.contact_signed_location || data.address?.municipality} onChange={(e) => orderHandler(e, 'contact_signed_location')} /></span></td>
                                            <td colSpan={2}><span>{translate('on')}: <OsnInputDate disabled={editable ? false : true} defaultValue={order.order_start || new Date().toISOString().split('T')[0]} onChangeDate={(e) => orderHandler(e, 'contact_signed_date')} /></span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} >
                                                <div dangerouslySetInnerHTML={{ __html: translate('before_signing_this_agreement') }} />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>{translate('signature_of_the_customer')} <span className="error">{translate('incl_read_and_aaproved')}</span></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                {order.signature !== "" ?
                                                    <>
                                                        <figure>
                                                            <img src={order.signature} />
                                                        </figure>
                                                        {editable && <button onClick={() => orderHandler("", "signature")}>Reset</button>}
                                                    </>
                                                    :
                                                    <>
                                                        <SignatureCanvas onEnd={(e) => { setSignarture(signatureCanvasRef.current.toDataURL()) }} ref={signatureCanvasRef} penColor='black' canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }} />
                                                        <button onClick={() => signatureCanvasRef.current.clear()}>Reset</button>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}><b>{translate('position_and_name_of_the_signatory')} <span className="error">{translate('fully_written')}</span></b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                {order.signer ?
                                                    <>
                                                        <figure>
                                                            <img src={order.signer} />
                                                        </figure>
                                                        {editable && <button onClick={() => orderHandler("", "signer")}>Reset</button>}
                                                    </>
                                                    :
                                                    <>
                                                        <SignatureCanvas onEnd={(e) => { setSigner(signerRef.toDataURL()) }} ref={(ref) => { signerRef = ref }} penColor='black' canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }} />
                                                        <button onClick={() => signerRef.clear()}>Reset</button>
                                                    </>
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4} className="th">
                                                {translate('this_agreement_only_has_value_and_binds_identityBuilding')}
                                            </td>
                                        </tr>
                                        {/* <tr>
                                            <td className="th error" colSpan={4}>**** {translate('inquire_about_the_exclusive_benefits_of_a_OnePage')}</td>
                                        </tr>
                                        <tr>
                                            <td className="th error" colSpan={4}>*** {translate('logo_for_free_in_the_companys_municipality_without_commitment')}</td>
                                        </tr> */}
                                        {/* <tr>
                                            <td className="th" colSpan={4} style={{ 'fontSize': '12px' }}>
                                                <span>{translate('general_terms_and_conditions_apply')}</span>
                                                <a target="_blank" href="https://identitybuilding.be/nl/algemene-voorwaarden/">www.identitybuilding.be/algemene-voorwaarden</a>
                                            </td>
                                        </tr> */}
                                        <tr>
                                            <td className="th" colSpan={4} style={{ 'fontSize': '12px' }}>
                                                {/* <span>{translate('our_general_terms_and_conditions_always_gets_sent')}</span> */}
                                                <span style={{ "display": "inline" }} dangerouslySetInnerHTML={{ __html: translate('our_general_terms_and_conditions_always_gets_sent') }} />

                                                {/* <a target="_blank" href="https://identitybuilding.be/nl/algemene-voorwaarden/">www.identitybuilding.be/algemene-voorwaarden</a> */}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={`section ${tab === 3 ? 'active' : ''}`}>
                            <h2><span>04</span>{translate('conditions')}</h2>
                            <div className="section-content">
                                <h4>{translate('known_promotion_and_additional_conditions_aply')}</h4>
                                <span style={{ "fontSize": "14px", "textAlign": "justify" }}>
                                    <div dangerouslySetInnerHTML={{ __html: translate('the_entrepreneur_was_informed_about_the_options_and_promotions_we_offer') }} />

                                    <br />
                                    <b><div dangerouslySetInnerHTML={{ __html: translate('promotional_terms_and_conditions_are_valid_for_the_first_year') }} /></b>
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: translate('identitybuilding_undertakes_to_fulfill_administrative_and_marketing_tasks') }} />
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: translate('identityBuilding_bv_will_maintain_regular_contact_with_entrepreneurs') }} />
                                    <br />
                                    <div dangerouslySetInnerHTML={{ __html: translate('identityBuilding_bv_does_not_charge_any_extra_costs_for_the_services') }} />

                                </span>

                                <h4>{translate('initials_or_mandatory_initials_included_read_and_approved')}</h4>
                                {order.initials ?
                                    <>
                                        <figure>
                                            <img src={order.initials} />
                                        </figure>
                                        {editable && <button onClick={() => orderHandler("", "initials")}>Reset</button>}
                                    </>
                                    :
                                    <>
                                        <SignatureCanvas onEnd={(e) => { setParaf(parafRef.toDataURL()) }} ref={(ref) => { parafRef = ref }} penColor='black' canvasProps={{ width: '500px', height: '200px', className: 'sigCanvas' }} />
                                        <button onClick={() => parafRef.clear()}>Reset</button>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="buttons" data-cy="action-buttons">
                        {tab != 0 && <Button borderColor="main" text={translate('previous')} txtColor="sub" type="sub" size="S" onClick={() => setHeigt(tab - 1)} />}
                        {tab != 3 && <Button borderColor="main" text={translate('next')} txtColor="sub" type="sub" size="S" onClick={() => { nextCheck(tab) }} />}
                        {tab == 3 && editable && <Button borderColor="main" text={translate('save')} txtColor="sub" type="sub" size="S" onClick={() => { editable && updateHandler() }} />}
                    </div>
                </div>
            }
            <div className="background" onClick={() => dispatch(openOrder(false))}></div>

        </div >
    );
};

export default OrderModule;
