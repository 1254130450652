import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import Modal from "react-modal";
import Icon from "@identitybuilding/idb-react-iconlib";
import store from "../store";
import { Button, OsnCheckbox, OsnTextarea } from "@identitybuilding/idb-react-ui-elements";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

const Contest = (props) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(true);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();

  const questionRef = useRef();

  const patchData = (data, tab) => {
    let copy = data
    if(tab > copy.available_tabs){copy.available_tabs = tab}
    copy.completed = true
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "contest"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };
  const Change = (event, type) => {
    let copy = data;
    if (type === "interest_in_businesspage" || type === "contact_me" || type === "enter_competition") {
      copy[type] = !copy[type];

      if (copy.contact_me === false) {
        copy.contact_me_question = "";
      }
    } else if (type === "contact_me_question") {
      copy[type] = event.target.value;
    }

    setData(copy);
    dispatch(updateCurrentEstablishment(copy));
    setLoaded(false);
  };

  const sendMail = (e) => {
    e.preventDefault();
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: "1000px",
      height: "500px",
      maxHeight: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setData(props.data);
    setLoaded(true);
  });

  return (
    <section className="c-contest">
      <div className="intro">
        <h2>
          <span className="typo">11</span>
        </h2>
        <div>
          <h2>
            {data.enter_competition && translate('three_more_points')}
            {!data.enter_competition && translate('tow_more_points')}

          </h2>
          <p>
            {translate('congratulation_completed')}
            <br /> 
            {translate('we_assume')} 
            <b className="yellow"> {translate('no_obbligation_to_renew')}</b>
          </p>
        </div>
      </div>

      {data && (
        <section className="section-container">
          <div className="c-contest__business">
            <p className="help">{translate('interested_in_bp')}</p>
            {data.interest_in_businesspage ? <span style={{"minHeight": "18px"}} className="help">

              {translate('show_bp')} -&nbsp;<a href={`https://ondernemersnetwerk.be/business/${data.establishment && data.establishment.search_id}/${data[`official_name_${lang}`] || data[`official_name_nl`] || data[`official_name_fr`] || data[`official_name_de`] || data[`official_name_en`]}/contact`} target="_blank" className="sub bold">{translate('more_info')}</a>
            </span> : 
            <span style={{"display": "block","height": "18px"}}></span>}
            <div onClick={(e) => Change(e, "interest_in_businesspage")} className={["option", data.interest_in_businesspage ? "active" : ""].join(" ")}>
              <p className="typo">{translate('ci_yes')}</p>
              <p className="typo">{translate('ci_no')}</p>
            </div>
            {!data.interest_in_businesspage && (
              <div className={[data.interest_in_businesspage ? "active" : "", "false-info"].join(" ")}>
                <Icon name="Info" />
                <p>{translate('only_logo_address_and_phone_online_info')}</p>
              </div>
            )}
            {data.interest_in_businesspage && (
              <div className={[data.interest_in_businesspage ? "active" : "", "false-info"].join(" ")}>
                <Icon name="Info" />
                <p>{translate('all_online_info')}</p>
              </div>
            )}

            <div className="switch-container">
              {data.interest_in_businesspage && (
                <Button
                  text={translate('show_bp_finish')}
                  type="sub"
                  size="S"
                  brderColor="main"
                  onClick={(e) => {
                    window.open(`https://${data.address.slug.replace(' ', '-')}.100procentlokaal.be/business/${data.establishment.search_id}/${data[`commercial_name_${lang}`] || data.commercial_name_nl || data.commercial_name_fr || data.commercial_name_de || data.commercial_name_en || data.official_name_nl || data.official_name_fr || data.official_name_fr || data.official_name_en}/contact/`, "_blank");
                  }}
                />
              )}
              {console.log(data.address.slug)}
              {!data.interest_in_businesspage && (
                <Button
                  text={translate('show_bp_button')}
                  type="sub"
                  size="S"
                  brderColor="main"
                  onClick={(e) => {
                    window.open(`https://${data.address.slug.replace(' ', '-')}.100procentlokaal.be/zoeken/in/${data.address.slug.replace(' ','-')}/naar/${data[`commercial_name_${lang}`] || data.commercial_name_nl || data.commercial_name_fr || data.commercial_name_de || data.commercial_name_en}/pagina=1`, "_blank");
                  }}
                />
              )}
            </div>
          </div>
          <div className="c-contest__contact">
            <OsnCheckbox
              value={translate('have_question')}
              checked={data.contact_me}
              name="contact_check"
              onChange={(e) => {
                Change(e, "contact_me");
              }}
            />

            {data.contact_me && (
              <Fragment>
                <form className="question">
                  <OsnTextarea
                    defaultValue={data.contact_me_question}
                    placeholder={translate('post_question')}
                    size="S"
                    onChange={(e) => {
                      Change(e, "contact_me_question");
                    }}
                  />
                  <span className="help">
                    <Icon name="Info" className="Info" />
                    {translate('our_advisor')}
                  </span>
                </form>
              </Fragment>
            )}
          </div>
          {data.enter_competition && (
            <div className="c-contest__contest">
              <b>{translate('earlier_you_decided')}</b>
              <p>
                {translate('please_confirm')}
                <br />
                {translate('as_stated_introduction')}
              </p>

              <span className="help">
                <Icon name="Info" className="Info"/> {translate('by_clicking_on')}
              </span>
            </div>
          )}
        </section>
      )}
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(9);
            window.location.reload();
          }}
        />
        {!data.enter_competition && (
          <Button
            text={translate('next')}
            type="sub"
            size="S"
            brderColor="main"
            onClick={(e) => {
              patchData(data, 11);
              store.dispatch({ type: "countUp" });
              props.setTab(11);
            }}
          />
        )}
        {data.enter_competition && (
          <Button
            text={translate('participation_contest')}
            type="sub"
            size="S"
            brderColor="main"
            onClick={(e) => {
              patchData(data, 11);
              store.dispatch({ type: "countUp" });
              props.setTab(11);
            }}
          />
        )}
      </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <iframe height={450} width={1000} src="https://www.ondernemersnetwerk.be/business/${ent}/plenny/contact"></iframe>
      </Modal>
    </section>
  );
};

export default Contest;
