import Icon from "@identitybuilding/idb-react-iconlib";
import { Button, OsnCheckbox, OsnDndUpload, OsnInputDate, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import axios from "axios";
import { Fragment, useEffect, useState } from "react";
import store from "../store";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

function useForceUpdate(){
  const [value, setValue] = useState(0); // integer state
  return () => setValue(value => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here 
  // is better than directly setting `value + 1`
}


const Contactperson = (props) => {
  const [data, setData] = useState(props.data);
  const [loaded, setLoaded] = useState(false);
  const lang = useSelector((state) => state.general.lang);
  const dispatch = useDispatch();
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();
  const forceUpdate = useForceUpdate();

  const patchData = (data, tab) => {
    let copy = data
        if(tab > copy.available_tabs){copy.available_tabs = tab}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "contact_persons"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changePerson = (index, type, value, target) => {
    let copy = data;
    if (type === "nl" || type === "fr" || type === "de" || type === "en") {
      if (value) {copy.contact_persons[index].contact_person.spoken_languages.push(type)}
      else {copy.contact_persons[index].contact_person.spoken_languages.pop(type)}
    }
    if (type === 'avatar') {
      copy.contact_persons[index].contact_person.avatar = value
      forceUpdate()
    }
    if (type === 'epc') {
      copy.contact_persons[index].contact_person.epc = value.target.value
    }
    if (type === 'biv') {
      copy.contact_persons[index].contact_person.biv = value.target.value
    }
    if (type === 'date_of_birth') {
      copy.contact_persons[index].contact_person.date_of_birth = value.target.value
    }
    if (type === 'prename') {
      copy.contact_persons[index].contact_person.first_name = value.target.value
    }
    if (type === 'name') {
      copy.contact_persons[index].contact_person.last_name = value.target.value
    }
    if (type === 'function_title') {
      copy.contact_persons[index].function_title = value.target.value
    }
    if (type === 'department') {
      copy.contact_persons[index].department = value.target.value
    }
    if (type === 'mobile') {
      if(copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'Mobile')) {
        let mobile = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'Mobile')
        if(value.target.value === ""){
          // copy.contact_persons[index].contact_person.personal_contact_methods.pop(mobile)
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== mobile)
        }
        else {
          let newValue = value.target.value.replace(/\D/g,'').replace("+32", "0").replace("+32 (0)", "0").replace("+32", "0").replace("0032", "0").replace("/", "").replace("/", "").replace("(0)", "0").replace(".", "").replace("-", "").replaceAll(" ", "")
          newValue = `${newValue.substring(0,4)} ${newValue.substring(4,7)} ${newValue.substring(7,10)}`
          mobile.value = newValue
        }
      }
      else {copy.contact_persons[index].contact_person.personal_contact_methods.push({value:value.target.value, medium:'Mobile'})}
    }
    if (type === 'email') {
      if(copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'Email')) {
        let email = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'Email')
        if(value.target.value === ""){
          // copy.contact_persons[index].contact_person.personal_contact_methods.pop(email)
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== email)

        }
        else {email.value = value.target.value}
      }
      else {copy.contact_persons[index].contact_person.personal_contact_methods.push({value:value.target.value, medium:'Email'})}
    }
    // if (type === 'whatsapp') {
    //   if(copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'WhatsApp')) {
    //     let whatsapp = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'WhatsApp')
    //     if(value.target.value === ""){
    //       // copy.contact_persons[index].contact_person.personal_contact_methods.pop(whatsapp)
    //       copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== whatsapp)

    //     }
    //     else {
    //       // remove all non numeric characters and replace +32 with 0
    //       const whatsappNumber = value.target.value.replace(/\D/g,'').replace("+32", "0").replace("+32 (0)", "0").replace("+32", "0").replace("0032", "0").replace("/", "").replace("/", "").replace("(0)", "0").replace(".", "").replace("-", "").replaceAll(" ", "")
    //       whatsapp.value = "https://wa.me/+32" + whatsappNumber
    //     }
    //   }
    //   else {copy.contact_persons[index].contact_person.personal_contact_methods.push({value:value.target.value, medium:'WhatsApp'})}
    // }

    if (type === 'whatsapp') {
      const personMethods = copy.contact_persons[index].contact_person.personal_contact_methods;
      let whatsapp = personMethods.find(val => val.medium === 'WhatsApp');
    
      // Handle empty input: Remove WhatsApp entry if it exists
      if (value.target.value === "") {
        if (whatsapp) {
          copy.contact_persons[index].contact_person.personal_contact_methods = personMethods.filter(item => item !== whatsapp);
        }
        return;  // Exit early since no further action is needed for an empty value
      }
    
      // Extract and clean the input number
      let inputNumber = value.target.value.replace(/\D/g, ''); // Remove all non-numeric characters
      let countryCode = "";
      const countryCodeMap = {
        "+31": "31",
        "+32": "32",
        "+33": "33",
        "+49": "49"
      };
    
      // Determine the country code and format accordingly
      for (const [code, num] of Object.entries(countryCodeMap)) {
        if (inputNumber.startsWith(num)) {
          countryCode = code;
          inputNumber = inputNumber.replace(new RegExp(`^${num}`), '0');  // Replace the country code with '0'
          break;
        }
      }

      if (!countryCode) {
        countryCode = "+32";
      }
    
      // Additional formatting rules
      inputNumber = inputNumber
        .replace(/\(0\)/g, '')            // Remove any (0) placeholders
        .replace(/\s+/g, '')              // Remove spaces
        .replace(/[\/\.\-\(\)\[\]\*]/g, '') // Remove slashes, dots, dashes, parentheses, brackets, asterisks
        .replace(/x\d+$/, '')             // Remove extensions (e.g., 1234567x89)
        .replace(/\bext\b\d+$/, '')       // Remove extensions written as 'ext'
        .replace(/^0+/, '');              // Remove leading zeros after formatting
    
      // Assign the final WhatsApp link based on the detected country code
      if (whatsapp) {
        whatsapp.value = `https://wa.me/${countryCode}${inputNumber}`;
      } else {
        personMethods.push({ value: `https://wa.me/${countryCode}${inputNumber}`, medium: 'WhatsApp' });
      }
    }
    

    if (type === 'linkedin') {
      if(copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'LinkedIn')) {
        let linkedin = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'LinkedIn')
        if(value.target.value === ""){
          // copy.contact_persons[index].contact_person.personal_contact_methods.pop(linkedin)
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== linkedin)

        }
        else {linkedin.value = value.target.value}
      }
      else {copy.contact_persons[index].contact_person.personal_contact_methods.push({value:value.target.value, medium:'LinkedIn'})}
    }
    if (type === 'fixed') {
      if(copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'Fixed')) {
        let fixed = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'Fixed')
        if(value.target.value === ""){
          // copy.contact_persons[index].contact_person.personal_contact_methods.pop(fixed)
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== fixed)

        }
        else {
          let newValue = value.target.value.replace(/\D/g,'').replace("+32", "0").replace("+32 (0)", "0").replace("+32", "0").replace("0032", "0").replace("/", "").replace("/", "").replace("(0)", "0").replace(".", "").replace("-", "").replaceAll(" ", "")
          let two_digits_prefix = ["02", "03", "04", "09"]
          if (newValue[0] != '+' && newValue != ''){
            if(two_digits_prefix.includes(newValue.substring(0,2))) {
              newValue = `${newValue.substring(0,2)} ${newValue.substring(2,5)} ${newValue.substring(5,7)} ${newValue.substring(7,9)}`
            }
            else {
              newValue = `${newValue.substring(0,3)} ${newValue.substring(3,5)} ${newValue.substring(5,7)} ${newValue.substring(7,9)}`
            }
          }
          
          fixed.value = newValue
        }
      }
      else {copy.contact_persons[index].contact_person.personal_contact_methods.push({value:value.target.value, medium:'Fixed'})}
    }
    setData(copy);
    dispatch(updateCurrentEstablishment(data));
    // setLoaded(false);
  };

  const addPerson = (e, index, target) => {
    let copy = data;

    let person = {
      contact_person: {
        first_name: "",
        last_name: "",
        date_of_birth: "",
        spoken_languages: [],
        biv: null,
        epc: null,
        personal_contact_methods: [
          {
            medium: "Email",
            value: "",
          },
          {
            medium: "Mobile",
            value: "",
          },
          {
            medium: "Fixed",
            value: "",
          },
        ],
        avatar: "",
      },
      business_contact_methods: [],
      function_title: "",
      section: "",
      avatar: "",
    };

    if (!copy.contact_persons) {
      copy.contact_persons = [person];
    } else {
      copy.contact_persons.push(person);
    }


    setData(copy);
    setLoaded(false);
  };

  const removePerson = (e, index) => {
    let copy = data;

    copy.deleted_contact_persons.push(copy.contact_persons[index])
    copy.contact_persons.splice(index, 1);
    setData(copy);
    setLoaded(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setData(data);
    setLoaded(true);
  });

  const getContactData = (item, type) => {
    for (let i = 0; i < item.contact_person.personal_contact_methods.length; i++) {
      let object = item.contact_person.personal_contact_methods[i];
      if (type === object.medium) {
        return object.value;
      }
    }
  };

  return (
    <section className="c-contact-person">
      <div className="intro">
        <h2>
          <span className="typo">07</span>
        </h2>
        <div>
          <h2>{translate('contacts')}</h2>
          <p>
            {translate('because_certain_companies')}
            <br />
            <span>
              <span className=" yellow">{translate('eg')} </span>
              {translate('a_department_manager')}
            </span>
          </p>
        </div>
      </div>

      {loaded && (
        <Fragment>
          <div className="container">
            <div className="plus-button">
              <Button onClick={(e) => addPerson(e)} size="S" icon="Add" text={translate('add')} type="sub" />
            </div>
            <div className="card-container">
              {data.contact_persons.map((item, index) => (
                <div className="item" key={index}>
                  <OsnInputText icon="UserM" defaultValue={item.contact_person.first_name && item.contact_person.first_name} title={translate('ci_prename')} onChange={(e) => changePerson(index, 'prename',e )} />

                  <OsnInputText icon="UserM" defaultValue={item.contact_person.last_name && item.contact_person.last_name} title={translate('ci_name')} onChange={(e) => changePerson(index, 'name',e)} />

                  <OsnInputText icon="Chain" defaultValue={item.function_title && item.function_title} title={translate('function')} onChange={(e) => changePerson(index, 'function_title',e)} />

                  <OsnInputText icon="Chain" defaultValue={item.department && item.department} title={translate('department')} onChange={(e) => changePerson(index, "department", e)} />

                  <OsnInputText icon="Cellphone" defaultValue={getContactData(item, "Mobile")} title={translate('ci_mobile')} onChange={(e) => changePerson(index, 'mobile',e)} />
                  <OsnInputText icon="Phone" defaultValue={getContactData(item, "Fixed")} title={translate('ci_phone')} onChange={(e) => changePerson(index, 'fixed',e)} />
                  <OsnInputText icon="Mail" defaultValue={getContactData(item, "Email")} title={translate('email_never_visible')} onChange={(e) => changePerson(index, 'email',e)} />
                  <OsnInputText icon="Whatsapp" defaultValue={getContactData(item, "WhatsApp")} title={translate('whatsapp')} onChange={(e) => changePerson(index, 'whatsapp',e)} />
                  <OsnInputText icon="Linkedin" defaultValue={getContactData(item, "LinkedIn")} title={translate('linkedin')} onChange={(e) => changePerson(index, 'linkedin',e)} />

                  <label id="birthdate">
                    <div>
                      <p className="label">{translate('date_of_birth_not_published')}</p>
                      <OsnInputDate
                        defaultValue={item.contact_person.date_of_birth}
                        onChangeDate={(e) => {
                          changePerson(index, 'date_of_birth',e);
                        }}
                        template={1}
                        pattern={"\d{2}-\d{2}-\d{4}"}
                      />
                    </div>
                  </label>

                  <label>
                    <div className="avatar">
                      <p className="label">{translate('avatar')}</p>

                      <OsnDndUpload 
                        maxSize={500000000}
                        type="image"
                        getBase64={(e) => changePerson(index, 'avatar',e)}
                        onDrop={(e) => changePerson(index, 'avatar',e)}
                      />
                    {typeof item.contact_person.avatar === 'object' ? 
                        <img className="avatarImage" src={`${item.contact_person.avatar[0].fileResult}`} />
                      :
                        item.contact_person.avatar.length > 0 && <img className="avatarImage" src={item.contact_person.avatar} /> 
                    }
                    </div>
                  </label>

                  <label>
                    {translate('spoken_languages')}
                    <div className="language-container">
                      <div>
                        <OsnCheckbox
                          checked={item.contact_person.spoken_languages.includes("nl")}
                          name={`${index} dutch`}
                          onChange={(e) => {
                            changePerson(index, 'nl',e);
                            setLoaded(false);
                          }}
                          value="NL"
                        />
                      </div>
                      <div>
                        <OsnCheckbox
                          checked={item.contact_person.spoken_languages.includes("fr")}
                          name={`${index} french`}
                          onChange={(e) => {
                            changePerson(index, 'fr',e);
                            setLoaded(false);
                          }}
                          value="FR"
                        />
                      </div>
                      <div>
                        <OsnCheckbox
                          checked={item.contact_person.spoken_languages.includes("de")}
                          name={`${index} german`}
                          onChange={(e) => {
                            changePerson(index, 'de',e);
                            setLoaded(false);
                          }}
                          value="DE"
                        />
                      </div>
                      <div>
                        <OsnCheckbox
                          checked={item.contact_person.spoken_languages.includes("en")}
                          name={`${index} english`}
                          onChange={(e) => {
                            changePerson(index, 'en',e);
                            setLoaded(false);
                          }}
                          value="EN"
                        />
                      </div>
                    </div>
                  </label>

                  <div className="remove-container">{data.contact_persons.length !== 0 && <Icon onClick={(e) => removePerson(e, index)} name="Bin" />}</div>
                </div>
              ))}
            </div>
            {(!data.contact_persons || data.contact_persons.length === 0) && <p>Gelieve hierboven een contact persoon toe te voegen.</p>}
          </div>
        </Fragment>
      )}
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(5);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            patchData(data, 7);
            store.dispatch({ type: "countUp" });
            props.setTab(7);
          }}
        />
      </div>
    </section>
  );
};

export default Contactperson;
