import Icon from "@identitybuilding/idb-react-iconlib";
import { Button, OsnInputText, OsnTagLabel } from "@identitybuilding/idb-react-ui-elements";
import store from "../store/index";
import axios from "axios";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton } from "react-share";

import plennyParty from "../library/images/plenny-party.png";
import { useEffect, useRef, useState } from "react";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { set } from "store";
import { useHistory, useParams } from "react-router-dom";
import { NotificationManager } from "react-notifications";


const Finish = (props) => {
  const [sug, setSug] = useState([]);
  const history = useHistory();
  const inputRef = useRef();
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState(props.data);
  const [input, setInput] = useState("");
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  const isAdmin = useSelector((state) => state.general.is_admin);
  let { est, ent } = useParams();


  const patchData = (data, tab) => {
    let copy = data
    if (tab > copy.available_tabs) { if (tab > copy.available_tabs) { copy.available_tabs = tab } }
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
        headers: headers,
        data: copy,
        token: localStorage.getItem("token"),
        page: "finish"
      })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const addSug = (e, index) => {
    let copy = sug;
    let dataCopy = data
    if (e || e === "") {
      if (e === "") { }
      else if (copy.includes(input)) { props.createNotification("warning", "deze actie is niet mogelijk aangezien het opgegeven email al in de lijst staat.") }
      else {
        copy.push(e)
      }
    }
    else {
      if (copy.includes(input)) { setError(true); props.createNotification("warning", "deze actie is niet mogelijk aangezien het opgegeven email al in de lijst staat.") }
      else if (input === "") { setError(true); props.createNotification("warning", "Gelieve iets in te vullen") }
      else if (!input.includes("@")) { setError(true); props.createNotification("warning", "Gelieve een geldige email in te vullen") }
      else {
        copy.push(input);
        setInput("")
      }
    }

    dataCopy.colleague_emails = copy.toString()
    setSug(copy);
    setData(dataCopy)
    dispatch(updateCurrentEstablishment(dataCopy))
    setLoaded(true);
    // if (!e) {
    //   patchData(dataCopy, 12)
    // }
  };

  const removeSug = (e, index) => {
    let dataCopy = data
    let copy = [...sug];
    copy.splice(index, 1);
    dataCopy.colleague_emails = copy.toString()
    setSug(copy);
    setData(dataCopy)
    dispatch(updateCurrentEstablishment(dataCopy))
    setLoaded(true);
    // patchData(dataCopy, 12)
  };


  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    setSug([])
    data.colleague_emails.split(',').forEach((res, index) => {
      addSug(res)
    })
  }, []);

  return (
    <section className="c-finish">

      <div className="intro">
        <h2>
          <span className="typo">12</span>
        </h2>
        <div>
          <h2>
            {translate('congrats_done')} <span className="typo sub">{translate('free')}</span>!{" "}
          </h2>
        </div>
      </div>
      <section>
        <div className="c-finish__thanks">
          {
            lang === 'nl' ? <p>We willen je van<b>harte bedanken!</b></p>
              : lang === 'fr' ? <p>Nous tenons à vous <b>remercier chaleureusement !</b></p>
                : <p>We would like to<b>thank you</b>very much!</p>
          }

          <p>
            {
              lang === 'nl' ? <p>Wij waarderen je inzet en hierdoor wordt <b>jouw onderneming</b>lokaal zichtbaarder.</p>
                : lang === 'fr' ? <p>Nous apprécions vos efforts et cela rend <b>votre entreprise</b>plus visible localement.</p>
                  : <p>We appreciate your efforts and this makes<b>your company</b>more visible locally.</p>
            }
          </p>
          <p>
            {translate('because_the_internet')}
            <br />
            {translate('we_are_not_yet_finished')}
          </p>
          <p>{translate('click_next_button')}</p>
          <Button
            text={translate('show_bp_finish')}
            type="sub"
            size="S"
            brderColor="main"
            onClick={(e) => {
              window.open(`https://${props.data.address.slug || props.data.address.sub_slug}.100procentlokaal.be/business/${props.data.establishment.search_id}/${data[`commercial_name_${lang}`] || data.commercial_name_nl || data.commercial_name_fr || data.commercial_name_de || data.commercial_name_en}/contact/`, "_blank");
            }}
          />
          <br />
          <p className="help">
            {translate('you_have_just_reached')}
            <br />
            {translate('will_you_also_show')}
            <br />
            {translate('you_can_also_send')}
            <br />
            {translate('they_too_will_enjoy')}
          </p>
          <div className="share__container">
            <button
              onClick={(e) => { window.open("https://www.facebook.com/identityBuildingBelgium", "_blank"); }}
              className="share facebook"
            >
              <Icon name="Facebook" />
            </button>
            <button
              onClick={(e) => { window.open("https://www.instagram.com/identitybuilding/", "_blank"); }}
              className="share instagram"
            >
              <Icon name="Instagram" />
            </button>
            <button
              onClick={(e) => { window.open("https://www.linkedin.com/company/identitybuildingbv/", "_blank"); }}
              className="share linkedin"
            >
              <Icon name="Linkedin" />
            </button>
            <button
              onClick={(e) => { window.open("https://twitter.com/idBuilding_", "_blank"); }}
              className="share twitter"
            >
              <Icon name="Twitter" />
            </button>
            <button
              onClick={(e) => { window.open("https://www.youtube.com/channel/UCuf7qUgrrMGhVPoGrgMMxZw", "_blank"); }}
              className="share youtube"
            >
              <Icon name="Youtube" />
            </button>
          </div>

          <div className="extra">
            <div ref={inputRef} className="input-container">
              <OsnInputText error={error} onChange={(e) => { setInput(e.target.value); setError(false) }} value={input} name={"extra"} icon="Mail" size="M" placeholder={translate('add_an_email_address')} />
              <Icon onClick={(e) => addSug()} name="AddCircle" />
            </div>
            <span style={{ "margin": "10px 0" }} className="help">
              <Icon name="Info" />
              <div style={{ display: 'block' }}>
                {translate('press_the_plus_sign')} <br />
                {translate('this_way_multiple_email')}
              </div>
            </span>
            <div className="button-container">
              {sug.length > 0 && sug.map((item, index) => (
                <button key={index} className={["select-button", "active"].join(" ")}>
                  <Icon name="CloseCircle" onClick={(e) => removeSug(e, index)} />
                  <span>{item}</span>
                </button>
              ))}
            </div>
          </div>

        </div>
        <div className="image-container">
          <img className="c-finish__plenny-cards" alt="plennt logo" src={plennyParty} />
        </div>
      </section>
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(10);
          }}
        />
        <Button
          text={translate('ci_close')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            patchData(data, 12);
            if (!isAdmin) {
              history.push(`/${ent}/${est}/login?lang=${lang}`)
              localStorage.removeItem('token');
            }
            else NotificationManager.success("Alle gegevens zijn opgeslagen in de klanteninfo")
          }}
        />
      </div>
    </section>
  );
};

export default Finish;
