import { Fragment, useState, useEffect } from "react";
import Icon from "@identitybuilding/idb-react-iconlib";

import hankard from "../library/logos/hankard.png";
import angeloVercauteren from "../library/logos/angeloVercauteren.png";
import cleaning from "../library/logos/cleaning.png";
import luxas from "../library/logos/luxas.png";
import onor from "../library/logos/onor.png";
import newEnergy from "../library/logos/newEnergy.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import download from "../library/images/download.png";
import store from "../store";
import { Button, OsnDndUpload, OsnInputText, OsnCheckbox } from "@identitybuilding/idb-react-ui-elements";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import axios from "axios";

const Logo = (props) => {
  // const [hasLogo, setHasLogo] = useState(props.data.logo ? props.data.logo : props.data.establishment_logo.length > 0 ? true : false);
  const [hasLogo, setHasLogo] = useState(props.data.has_logo);
  const [logoProposal, setLogoProposal] = useState(props.data.contact_me_for_logo_proposition);
  const [localFile, setLocalFile] = useState("");
  const [externalFile, setExternalFile] = useState(false);
  const [contact, setContact] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [noError, setNoError] = useState(true);
  const [data, setData] = useState(props.data);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();


  useEffect(() => {
    if (props.data.has_logo === 'unknown' && (props.data.logo || props.data.establishment_logo.length > 0)) {
      setHasLogo('true')
    }
    else if (props.data.has_logo === 'true') {
      setHasLogo('true')
    }
    else {
      setHasLogo('false')

    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

  }, [])
  
  const patchData = (data, tab) => {
    let copy = data
    if ((copy.digital_agancy_name && copy.digital_agancy_phone) || (copy.digital_agancy_email)) {copy.contact_digital_agancy = true}
    else { copy.contact_digital_agancy = false }
    copy.has_logo = hasLogo
    

    if(tab > copy.available_tabs){    if(tab > copy.available_tabs){copy.available_tabs = tab}}
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)
    if (typeof copy.logo === 'object') { 
      props.createNotification("success", "Proficiat! We hebben jouw logo goed ontvangen. Na controle zal dit zichtbaar zijn!")
    }
    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "logo"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const changeData = (value, type) => {
    let copy = data
    if (type === "contact_me_for_logo_proposition") {copy[type] = !copy[type]}
    else {copy[type] = value}
    setData(copy)
    dispatch(updateCurrentEstablishment(copy))

  }
  
  const changeLogo = (e) => {
    let format = e[0].fileResult.split(';base64,')
    let ext = format[0].split('/')[1].replace('+xml', '')
    let available_extentions = ['illustrator', 'ai', 'svg', 'cdr', 'eps', 'pdf']
    if (available_extentions.includes(ext)) {
      setNoError(true)
      setLocalFile(e)
      let copy = data
      data.logo = e
      setData(copy)
      setLoaded(false);
      setLoaded(true);
      dispatch(updateCurrentEstablishment(copy))
    }
    else { 
      setNoError(false)
      props.createNotification("error", `${ext} is geen toegelaten extentie`)
    }
  }

  
  return (
    <section className="c-logo">
      <div className="intro">
        <h2>
          <span className="typo">05</span>
        </h2>
        <div>
          <h2>{translate('with_you_logo_you_stand_out_more')}</h2>
          <p>
            {translate('about_85_percent')}
            <br />
            {translate('with_your_logo')} <b className="yellow">{translate('guaranteed')}</b> {translate('front_of_the_results')}{" "}
          </p>
        </div>
      </div>
      <div className="c-logo__container">
        <div className="c-logo-option__container">
          <div className="c-logo-option first">
            <div className="section">
              <p>{translate('company_has_a_logo')}</p>
              <div
                onClick={() => {setHasLogo(hasLogo === 'true' ? 'false' : 'true');}}
                className={["option", hasLogo === 'true' ? "active" : ""].join(" ")}
              >
                <p className="typo">{translate('ci_yes')}</p>
                <p className="typo">{translate('ci_no')}</p>
              </div>
              {hasLogo === 'true' && (
                <Fragment>
                  <div>
                    <h4>{translate('upload_logo_here')}</h4>
                    <OsnDndUpload 
                      maxSize={500000000}
                      type="image"
                      getBase64={(e) => changeLogo(e)}
                      onDrop={(e) => changeLogo(e)}
                      noError={noError}
                    />
                    <span className="help">
                      <Icon name="Info" />
                      <div style={{display: 'block'}}>
                        {translate('allowed_file_formats')} <br />
                        <b className=" yellow">{translate('attention')}</b> {translate('logo_will_be_checked')}
                      </div>
                    </span>
                      <div className="logo-container">
                        {data.logo.includes('.pdf') ?
                          <iframe src={`${data.logo}`} />
                          : typeof data.logo === 'object' ? 
                          data.logo[0].fileName.includes(".pdf") ?
                            ""
                          :
                            <img src={`${data.logo[0].fileResult}`} />
                        :
                        <img src={data.logo ? data.logo : data.establishment_logo.length > 0 && data.establishment_logo[0].logo} />
                        }
                        
                      </div>
                    <p className="info">
                      <span className="typo">{translate('logo_uploaded_by')} </span>
                      {data.logo ? 
                      <strong>{translate('ci_entrepreneur')}</strong>
                      :
                      <strong>{data.establishment_logo.length > 0 && translate('our_database')}</strong>
                      }
                    </p>
                  </div>

                  <div className="button-container">
                    <Button
                      text={translate('save_my_logo')}
                      type="sub"
                      size="S"
                      brderColo="main"
                      onClick={(e) => {
                        patchData(data, 5)
                      }}
                    />
                  </div>
                </Fragment>
              )}
              {hasLogo === 'false' && (
                <Fragment>
                  <div>
                    <h2 className="typo title">{translate('but_i_am_interested')}</h2>
                    <p>
                      {translate('you_want_unique_logo')}
                      <br />
                      {translate('you_can_contact_us_for_this')} <br /> {translate('idb_shows_some_creations')}
                    </p>
                    <div className="logo-examples">
                      <div className="logo-container">
                        <img alt="logo Roland Hankard" src={hankard} />
                      </div>
                      <div className="logo-container">
                        <img alt="logo angelo vercauteren" src={angeloVercauteren} />
                      </div>
                      <div className="logo-container">
                        <img alt="logo cleaning" src={cleaning} />
                      </div>
                      <div className="logo-container">
                        <img alt="logo luxas" src={luxas} />
                      </div>
                      <div className="logo-container">
                        <img alt="logo onor" src={onor} />
                      </div>
                      <div className="logo-container">
                        <img alt="logo new energy" src={newEnergy} />
                      </div>
                    </div>
                    <OsnCheckbox checked={logoProposal} type="checkbox" name="dataAgree" value={translate('contact_me_for_proposal')} onChange={(e) => {setLogoProposal(!logoProposal) ;changeData(e, 'contact_me_for_logo_proposition')}} />
                  </div>
                </Fragment>
              )}
            </div>
          </div>
          <div className="c-logo-option">
            {hasLogo === 'true' && (
              <Fragment>
                <div>
                  <h4>{translate('contact_my_printer')}</h4>
                  <div>
                    <OsnInputText defaultValue={data.digital_agancy_name} icon="UserM" title={translate('company_name_or_responsible_person')} onBlur={(e) => changeData(e.target.value, 'digital_agancy_name')} />
                  </div>
                  <div>
                    <OsnInputText defaultValue={data.digital_agancy_phone}    icon="Phone" title={translate('ci_phone')} onBlur={(e) => changeData(e.target.value, 'digital_agancy_phone')}/>
                  </div>
                  <span className="help">
                    <Icon name="Info" /> {translate('you_will_automatically_receive_a')}&nbsp;
                    <b className="yellow">{translate('ci_copy')}</b>&nbsp;{translate('of_this_email')}
                  </span>
                  <div>
                    <OsnInputText defaultValue={data.digital_agancy_email} icon="Mail" title={translate('email_contact_person')} onBlur={(e) => changeData(e.target.value, 'digital_agancy_email')}/>
                  </div>
                </div>
                <div className="button-container">
                  <Button
                    text={translate('this_is_the_data')}
                    type="sub"
                    size="S"
                    brderColo="main"
                    onClick={(e) => {
                      e.preventDefault();
                      setExternalFile(true);
                      store.dispatch({ type: "countUp" });
                      props.setTab(5);
                      patchData(data, 5)
                    }}
                  />
                </div>
              </Fragment>
            )}

            {hasLogo === 'false' && (
              <Fragment>
                <div>
                  <h2 className="typo title">{translate('and_i_am_not_interested')}</h2>
                  <p>
                    {translate('with_a_logo_you')}
                    <br /> {translate('without_logo')}
                  </p>
                  <div className="c-logo__image-container">
                    <img alt="logo" className="c-logo__image" src={download} />
                  </div>
                  <div className="button-container">
                    <Button
                      text={translate('yes_i_am_sure')}
                      type="sub"
                      icon="Sad"
                      size="S"
                      brderColor="main"
                      onClick={(e) => {
                        e.preventDefault();
                        store.dispatch({ type: "countUp" });
                        props.setTab(5);
                        patchData(data, 5)

                      }}
                    />
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
        <div className="button-container">
          <Button
            text={translate('previous')}
            type="sub"
            size="S"
            brderColor="main"
            onClick={(e) => {
              store.dispatch({ type: "countDown" });
              props.setTab(3);
            }}
          />
          <Button
            text={translate('next')}
            type="sub"
            size="S"
            brderColor="main"
            onClick={(e) => {
              e.preventDefault();
              store.dispatch({ type: "countUp" });
              props.setTab(5);
              patchData(data, 5)
            }}
          />
        </div>
      </div>
    </section>
  );
};

export default Logo;
