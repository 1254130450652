import { Fragment, useEffect, useRef, useState } from "react";
import Icon from "@identitybuilding/idb-react-iconlib";
import axios from "axios";
import store from "../store";
import { Button, OsnCheckbox, OsnInputText } from "@identitybuilding/idb-react-ui-elements";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useHistory, useParams } from "react-router-dom";

const titles = document.getElementsByClassName("faciTitle");

const Faciliteiten = (props) => {
  const [faciTab, setFaciTab] = useState(localStorage.getItem("faciTab"));
  const [sug, setSug] = useState([]);
  const [tab, setTab] = useState("Betaalmethoden");
  const [otherFacility, setOtherFacility] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState({});
  const [facilities, setFacilities] = useState(props.current_establishment.facilities);
  const [searchValue, setSearchValue] = useState("");
  const [allData, setAllData] = useState([]);
  const [searchRes, setSearchRes] = useState([]);
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();

  const inputRef = useRef();

  const patchData = (data, tab) => {
    let copy = props.current_establishment
    if(tab > copy.available_tabs){copy.available_tabs = tab}
    if (sug.length > 0) {copy.other_facilities = sug.toString()}
    else {copy.other_facilities = ""}
    
    dispatch(updateCurrentEstablishment(copy));
    // setData(copy)
    if (localStorage.getItem("token") ) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
          headers: headers,
          data: copy,
          token: localStorage.getItem("token"),
          page: "facilities"
        })
        .then(async (res) => {
          setLoaded(false);
          setLoaded(true);
        });
    }
  };

  const fetchData = () => {
    let copy = {};
    axios.get(`${process.env.REACT_APP_BASE_MEDIA_URL}/core/api/facilities/?lang=${lang}`).then((res) => {
      let allCopy = allData;
      allCopy.length = 0;
      for (let i = 0; i < res.data.facilities.length; i++) {
        if (!copy[`${res.data.facilities[i].group}`]) {
          copy[`${res.data.facilities[i].group}`] = [];
        }
        if (facilities.includes(res.data.facilities[i].id)) {
          copy[`${res.data.facilities[i].group}`].push({
            ...res.data.facilities[i],
            active: true,
          });
          res.data.facilities[i].active = true
        } else {
          copy[`${res.data.facilities[i].group}`].push({
            ...res.data.facilities[i],
            active: false,
          });
          res.data.facilities[i].active = false
        }
        allCopy.push(res.data.facilities[i]);
      }

      setAllData(allCopy);
      setData(copy);
      setLoaded(true);
    });
  };

  const addSug = (e, type) => {
    let copy = sug;
    if(e && e !== "") { 
      if (copy.includes(e)) { if(type !== "useeffect") {props.createNotification("warning", "deze actie is niet mogelijk aangezien de opgegeven faciliteit al in de lijst staat.")}}
      else {
        setOtherFacility("")
        copy.push(e)
      }
    }

    setSug(copy);
    setLoaded(false);
  };

  const removeSug = (e, index) => {
    let copy = sug;
    copy.splice(index, 1);

    setSug(copy);
    setLoaded(false);
  };

  const search = (value) => {
    searchRes.length = 0;
    let copy = searchRes;
    allData.some((item, index) => {
      if (String(item.name).toLocaleLowerCase().includes(String(value))) {
        copy.push(allData[index]);
      }
    });
    setSearchRes(copy);
    setLoaded(true);
  };

  const ChangeFaciliteiten = (value, i, type) => {
    let copy = props.current_establishment;
    let dataCopy = data;
    let allDataCopy = allData;

    if (type === "search") {

      for (let x = 0; x < dataCopy[value.group].length; x++) {
        if (dataCopy[value.group][x].id === value.id) {
          dataCopy[value.group][x].active = !dataCopy[value.group][x].active;
          if(!dataCopy[value.group][x].active) { 
            copy.removed_facilities.push(value.id) 
            copy.facilities.splice(copy.facilities.indexOf(value.id), 1);
          }
          else { 
            if (copy.removed_facilities.includes(value.id)) {
              copy.removed_facilities.pop(value.id) 
            }
            copy.facilities.push(value.id) 

          }
        }
      }

    } 

    else {
      dataCopy[value.group][i].active = !dataCopy[value.group][i].active;
      if(!dataCopy[value.group][i].active) { copy.removed_facilities.push(value.id) }
      else { copy.removed_facilities.pop(value.id) }
      if (copy.facilities.includes(value.id)) {
        copy.facilities.splice(copy.facilities.indexOf(value.id), 1);
      } else {
        copy.facilities.push(value.id);
      }
    }

    for (let y = 0; y < allDataCopy.length; y++) {
      if (allDataCopy[y].id === value.id) {
        allDataCopy[y].active = !allDataCopy[y].active;
      }
    }


    setAllData(allDataCopy);
    setData(dataCopy);
    setFacilities(copy.facilities);
    setLoaded(false);
    dispatch(updateCurrentEstablishment(copy));
  };

  const changeTab = (e) => {
    if (tab === e) { setTab(e)}
    else { setTab(e) }

  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    fetchData();
    props.data.other_facilities.toString().replaceAll(' ','').split(',').forEach((res, index) => {
      addSug(res, 'useeffect')
    })
  }, []);

  useEffect(() => {
    setData(data);
    setLoaded(true);
  });

  const itemsToDisplay = searchValue ? searchRes : data;
  return (
    <section className="c-faciliteiten">
      <div className="intro">
        <h2>
          <span className="typo">09</span>
        </h2>
        <div>
          <h2>{translate('what_facilities')}</h2>
          <p>
            {translate('not_just_payment_facilities')}<br /> 
            {translate('many_other_services')} <br />
            {translate('give_them_as_much_as_paossible')}
          </p>
        </div>
      </div>
      {data && (
        <section className="faci-container">
          <div className="search-bar">
            <OsnInputText
              onChange={(e) => {
                setSearchValue(e.target.value);
                search(e.target.value);
              }}
              placeholder={translate('ci_search')}
            />
          </div>
          {searchValue ? (
            <div className="card-container">
              {itemsToDisplay &&
                itemsToDisplay.map((item, index) => (
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      ChangeFaciliteiten(item, index, "search");
                    }}
                    key={index}
                    className="item"
                  >
                    <div>
                      <figure>
                        <img src={item.icon} alt={item.name} />
                      </figure>
                      <p>{item.name}</p>
                    </div>
                    <OsnCheckbox
                      checked={item.active}
                      name={item.name}
                      onChange={(e) => {
                        ChangeFaciliteiten(item, index, "search");
                      }}
                    />
                  </div>
                ))}
            </div>
          ) : (
            Object.entries(itemsToDisplay).map(([key, value], index) => (
              <div key={key} className={`card ${tab === key ? "active" : ""}`}>
                <h3 tabIndex={index} onClick={(e) => changeTab(key)} className={`card-title faciTitle`}>
                  {+faciTab === index && <Icon name="ArrowDown" />}
                  {+faciTab !== index && <Icon name="ArrowUp" />}
                  {key}
                </h3>
                <div className="card-container">
                  {value.map((itm, i) => (
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        ChangeFaciliteiten(itm, i);
                      }}
                      key={i}
                      className="item"
                    >
                      <div>
                        <figure>
                          <img src={itm.icon} alt={itm.name} />
                        </figure>
                        <p>{itm.name}</p>
                      </div>
                      <OsnCheckbox checked={itm.active} name={itm.name} />
                    </div>
                  ))}
                </div>
              </div>
            ))
          )}

          {data && (
            <div className="extra">
              <h3>{translate('other_possibilities')}</h3>
              <div ref={inputRef} className="input-container">
                <OsnInputText name={"extra"} value={otherFacility} icon="Edit" size="M" placeholder={translate('please_enter_facility')} onChange={(e) => setOtherFacility(e.target.value)} />
                <Icon onClick={(e) => addSug(otherFacility, 'input')} name="AddCircle" />
              </div>
              <div className="help">
                <Icon name="Info" />{translate('everyting_checked')}
              </div>
              <div className="button-container">
                {sug.map((item, index) => (
                  <button key={index} className={["select-button", "active"].join(" ")}>
                    <Icon name="CloseCircle" onClick={(e) => removeSug(e, index)} />
                    <p>{item}</p>
                  </button>
                ))}
              </div>
            </div>
          )}
        </section>
      )}
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(7);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countUp" });
            props.setTab(9);
            patchData(data, 9);
          }}
        />
      </div>
    </section>
  );
};

export default Faciliteiten;
