import { Fragment, useEffect, useRef, useState } from "react";
import axios from "axios";
import Icon from "@identitybuilding/idb-react-iconlib";
import store from "../store";
import { updateCurrentEstablishment } from "../actions/GeneralAction";
import { useDispatch, useSelector } from "react-redux";
import { Button, OsnInputText, OsnSelect } from "@identitybuilding/idb-react-ui-elements";
import { bind } from "lodash";
import { useHistory, useParams } from "react-router-dom";

const Search = (props) => {
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const [dropdownloaded, setDropdownLoaded] = useState(true);
  const [data, setData] = useState({
    ...props.data,
    contact_info: props.data.contact_info || {}
  });

  const [selected, setSelected] = useState(data.extra_categories);
  const [mainSelected, setMainSelected] = useState(data.main_customer_category);
  const [result, setResult] = useState([]);
  const [mainResult, setMainResult] = useState([]);
  const [BIV, setBIV] = useState([]);
  const [EPC, setEPC] = useState([]);
  const [apb, setApb] = useState();
  const [itaa, setItaa] = useState(data.itaa);
  const [fsma, setFsma] = useState(
    data.fsma.length === 0
      ? data.enterprise_number.replaceAll(".", "").slice(2)
      : data.fsma || ''
  );
  const lang = useSelector((state) => state.general.lang);
  const translate = useSelector((state) => state.general.translate);
  let { est, ent } = useParams();

  const searchRef = useRef();
  const searchMainRef = useRef();

  const publicFaxRef = useRef();
  const publicNameRef = useRef();
  const publicCellphoneRef = useRef();
  const publicPhoneRef = useRef();
  const publicEmailRef = useRef();
  const ITAARef = useRef();
  const FMSARef = useRef();
  const dispatch = useDispatch();
  const isMounted = useRef(false);


  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const patchData = (data, tab) => {
    // valisdate the email onli if provided
    if (data.business_email_nl && data.business_email_nl !== "") {
      if (!data.business_email_nl.includes("@") || !data.business_email_nl.includes(".")) {
        props.createNotification("error", "Gelieve een geldig email adres in te vullen.");
        return;
      }
    }
    let copy = data
    if (tab > copy.available_tabs) { copy.available_tabs = tab }
    dispatch(updateCurrentEstablishment(copy));
    setData(copy)

    // Function to remove objects from removed_extra_categories that are also in extra_categories
    const areObjectsEqual = (obj1, obj2) => {
      // Compare properties based on your specific requirements
      return obj1.code === obj2.code && obj1.active === obj2.active; // Add more comparisons as needed
    };

    // Function to check if two objects are equal
    const removeDuplicates = (sourceArray, removeArray) => {
      return removeArray.filter(removeItem => !sourceArray.some(sourceItem => areObjectsEqual(sourceItem, removeItem)));
    };

    // Remove duplicates
    copy.removed_extra_categories = removeDuplicates(copy.extra_categories, copy.removed_extra_categories);


    if (localStorage.getItem("token")) {
      const headers = { "Content-Type": "application/json", Authorization: `Token ${localStorage.getItem("token")}` };
      axios.post(`${process.env.REACT_APP_BASE_MEDIA_URL}/ci/update/${est}/?lang=${lang}`, {
        headers: headers,
        data: copy,
        token: localStorage.getItem("token"),
        page: "search"
      })
        .then(async (res) => {
          if (isMounted.current) { // Check if mounted
            setLoaded(false);
            setLoaded(true);
          }
        });

    }
    store.dispatch({ type: "countUp" });
    props.setTab(3);
  };

  const changeCategory = (e, action, type, category) => {
    const copy = { ...data };
    let sub = {
      active: true,
      code: e.id,
      category: {
        description_de: null,
        description_en: null,
        description_fr: null,
        description_nl: null,
        icon: null,
        image: null,
        name_de: null,
        name_en: null,
        name_fr: e.text,
        name_nl: e.text,
      }
    };

    if (action === 'ADD') {
      if (e) {
        copy.extra_categories.push(sub);
        result.length = 0;
        props.createNotification("info", "Extra category is toegevoegd.");
        setData(copy);
        setLoaded(false);
        return;
      }
    }
    if (action === "REMOVE") {
      copy.removed_extra_categories.push(category)
      copy.extra_categories.splice(type, 1);
    }

    if (action === "REMOVEMAIN") {
      if (copy.main_customer_category.category) {
        let main = {}
        // let main = { active: false, code: "", description_de: null, description_en: null, description_fr: null, description_nl: null, icon: null, image: null, name_de: null, name_en: null, name_fr: "", name_nl: "" };
        setMainSelected(main);
        copy.main_customer_category = main;
        setData(copy);
      }
    }

    if (action === "ADDMAIN") {
      if (copy.main_customer_category.category === undefined) {
        if (!selected.some((item) => item.name === e.text) && mainSelected.name !== e.text) {
          mainResult.length = 0;
          let main = {
            "id": 14334474,
            "active": true,
            "score": 1,
            "category": {
              "name": e.text,
              "name_nl": e.text,
              "name_fr": e.text,
              "name_de": null,
              "name_en": null,
              "description": null,
              "image": "",
              "icon": ""
            },
            "activity_type": 80,
            "is_main": true,
            "code": e.id
          }
          // let main = { code: e.id, description_de: null, description_en: null, description_fr: null, description_nl: null, icon: null, image: null, name_de: null, name_en: null, name_fr: e.text, name_nl: e.text };
          setMainSelected(main);
          copy.main_customer_category = main;
          // changeCategory(e, "REMOVE");
          props.createNotification("info", "Hoofdactiviteit gewijzigd");
          setData(copy);
        } else {
          props.createNotification("error", "Deze activiteit is al geselecteerd.");
        }
      } else {
        props.createNotification("error", "Bij de gratis versie is slechts 1 activiteit mogelijk!");
      }
    }

    if (action === "ACTIVE") {
      if (!selected[type].active && !copy.paid_version) {
        if (!copy.category.some((item) => item.active)) {
          copy.category[type].active = !copy.category[type].active;
        } else {
          props.createNotification("error", "Maar 1 hoofdactiviteit te gelijk");
        }
      } else if (copy.paid_version) {
        if (!selected[type].active) {
          selected[type].active = true;
        } else {
          selected[type].active = false;
        }
      } else {
        copy.category[type].active = false;
      }
    }

    if (action === "KBOSUBACTIVE" && copy.paid_version) {
      copy.kbo_extra_categories[type].active = !copy.kbo_extra_categories[type].active;
    }
    if (action === "KBOMAINACTIVE" && copy.paid_version) {
      copy.main[type].active = !copy.main[type].active;
    }
    setData(copy);
    setLoaded(false);
  };

  const changeContactPerson = (index, type, value, target) => {
    let copy = data;
    if (type === 'epc') {
      copy.contact_persons[index].contact_person.epc = value
    }
    if (type === 'biv') {
      copy.contact_persons[index].contact_person.biv = value
    }
    if (type === 'prename') {
      copy.contact_persons[index].contact_person.first_name = value
    }
    if (type === 'name') {
      copy.contact_persons[index].contact_person.last_name = value
    }
    if (type === 'mobile') {
      if (copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'Mobile')) {
        let mobile = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'Mobile')
        if (value === "") {
          // copy.contact_persons[index].contact_person.personal_contact_methods.pop(mobile)
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== mobile)
        }
        else { item.value = value }
      }
      else { copy.contact_persons[index].contact_person.personal_contact_methods.push({ value: value, medium: 'Mobile' }) }
    }
    if (type === 'email') {
      if (copy.contact_persons[index].contact_person.personal_contact_methods.find((val) => val.medium === 'Email')) {
        let email = copy.contact_persons[index].contact_person.personal_contact_methods.find((val, idx) => val.medium === 'Email')
        if (value === "") {
          copy.contact_persons[index].contact_person.personal_contact_methods = copy.contact_persons[index].contact_person.personal_contact_methods.filter(item => item !== email)
        }
        else { item.value = value }
      }
      else { copy.contact_persons[index].contact_person.personal_contact_methods.push({ value: value, medium: 'Email' }) }
    }
    setData(copy);
    dispatch(updateCurrentEstablishment(data));
    setLoaded(false);
  };

  const addContactPerson = (e, target) => {
    let copy = data;
    let item = {
      "contact_person": {
        "first_name": "",
        "last_name": "",
        "biv": "",
        "epc": "",
        "personal_contact_methods": [],
        "avatar": "",
        "date_of_birth": "",
        "spoken_languages": []
      },
      "business_contact_methods": [],
      "function_title": "",
      "avatar": ""
    }
    copy.contact_persons.push(item)
    setData(copy);
    dispatch(updateCurrentEstablishment(data));
    setLoaded(false);
  };

  const RemoveContactPerson = (index, target) => {
    let copy = data;
    copy.contact_persons.splice(index, 1)
    setData(copy);
    dispatch(updateCurrentEstablishment(data));
    setLoaded(false);
  };


  const changeHyperlink = (value, target) => {
    if (target) {
      let copy = { ...data };
      if (target === "APB") { copy.apb = value }
      else { copy[`business_${target}_nl`] = value; }
      // let values = copy.contact_info.filter((value) => value.contact_method.medium.toLowerCase() === target.toLowerCase())
      if (copy.contact_info.contact_method?.medium.toLowerCase() === target.toLowerCase()) {
        copy.contact_info.contact_method.value = value;
      } else {
        // Create or overwrite the contact_info object
        copy.contact_info = {
          "score": 10,
          "datasource": "CUSTOMER",
          "contact_method": {
            "medium": target.charAt(0).toUpperCase() + target.slice(1),
            "value": value,
            "name": "",
            "new_value": ""
          }
        };
      }
      setData(copy);
      dispatch(updateCurrentEstablishment(copy));
      setLoaded(false);
    }

  };

  const changePublic = (e) => {
    e.preventDefault();
    let copy = data;

    copy.commercial_name_nl = publicNameRef.current.childNodes[0].childNodes[0].childNodes[1].value;
    copy.business_email_nl = publicEmailRef.current.childNodes[0].childNodes[0].childNodes[1].value;
    copy.business_mobile_phone_nl = publicCellphoneRef.current.childNodes[0].childNodes[0].childNodes[1].value.replaceAll("+32 (0)", "0").replaceAll("+32", "0").replaceAll("0032", "0").replaceAll("/", "").replaceAll("/", "").replaceAll("(0)", "0").replaceAll(".", "").replaceAll("-", "").replaceAll(" ", "");
    copy.business_fixed_phone_nl = publicPhoneRef.current.childNodes[0].childNodes[0].childNodes[1].value.replaceAll("+32", "0").replaceAll("+32 (0)", "0").replaceAll("+32", "0").replaceAll("0032", "0").replaceAll("/", "").replaceAll("/", "").replaceAll("(0)", "0").replaceAll(".", "").replaceAll("-", "").replaceAll(" ", "");
    copy.business_fax_nl = publicFaxRef.current.childNodes[0].childNodes[0].childNodes[1].value.replaceAll("+32", "0").replaceAll("+32 (0)", "0").replaceAll("+32", "0").replaceAll("0032", "0").replaceAll("/", "").replaceAll("/", "").replaceAll("(0)", "0").replaceAll(".", "").replaceAll("-", "").replaceAll(" ", "");

    copy.business_website_nl = getContactInfo('Website')
    copy.business_facebook_nl = getContactInfo('Facebook')
    copy.business_instagram_nl = getContactInfo('Instagram')
    copy.business_youtube_nl = getContactInfo('YouTube')
    copy.business_linkedin_nl = getContactInfo('LinkedIn')
    copy.business_twitter_nl = getContactInfo('Twitter')
    copy.business_tiktok_nl = getContactInfo('TikTok')
    copy.business_snapchat_nl = getContactInfo('Snapchat')
    copy.business_spotify_nl = getContactInfo('Spotify')
    copy.apb = getContactInfo('APB')

    copy.fsma = fsma
    copy.itaa = itaa


    setData(copy);
    setLoaded(false);
    setLoaded(true);
  };

  const getSearchdata = (value, type) => {
    if (typingTimeout) clearTimeout(typingTimeout);

    setDropdownLoaded(false);
    let option = {
      size: 15,
      query: {
        bool: {
          must: [
            {
              query_string: {
                query: `*${value}*`,
                fields: ["name_nl", "name_fr", "name_de", "name_en"],
              },
            },
          ],
        },
      },
    };
    setTimeout(async () => {
      source.cancel();
    }, 1000);
    setTypingTimeout(
      setTimeout(async () => {
        axios
          .get(`${process.env.REACT_APP_BASE_MEDIA_URL2}/categories/_search`, {
            auth: { username: `${process.env.REACT_APP_ESUSERNAME}`, password: `${process.env.REACT_APP_ESPSW}`, },
            params: {
              source_content_type: "application/json",
              source: JSON.stringify(option),
            },
            cancelToken: source.token,
          })
          .then((res) => {
            if (isMounted.current) {
              if (type === "main" && data.main_customer_category !== undefined) {
                let copy = mainResult;
                mainResult.length = 0;

                for (let i = 0; i < res.data.hits.hits.length; i++) {
                  copy.push({
                    id: res.data.hits.hits[i]._id,
                    text: res.data.hits.hits[i]._source[`name_${lang}`] || res.data.hits.hits[i]._source.name_nl || res.data.hits.hits[i]._source.name_fr || res.data.hits.hits[i]._source.name_de || res.data.hits.hits[i]._source.name_en,
                  });
                }
                setMainResult(copy);
                setDropdownLoaded(true);
              } else if (type === "main") {
                props.createNotification("warning", "U heeft al een activiteit");
              }
              if (type === "sub" && data.paid_version) {
                let copy = result;
                result.length = 0;

                for (let i = 0; i < res.data.hits.hits.length; i++) {
                  copy.push({
                    id: res.data.hits.hits[i]._id,
                    text: res.data.hits.hits[i]._source[`name_${lang}`] || res.data.hits.hits[i]._source.name_nl || res.data.hits.hits[i]._source.name_fr || res.data.hits.hits[i]._source.name_de || res.data.hits.hits[i]._source.name_en,
                  });
                }
                setResult(copy);
                setDropdownLoaded(true);
              } else if (type === "sub") {
                props.createNotification("warning", "Dit hoort bij de betalende versie.");
              }
            }
          })
          .catch((err) => {
            if (isMounted.current) { // Check if mounted
              console.log(err);
            }
          });
      }, 500)
    );
  };

  const getContactData = (item, type) => {
    for (let i = 0; i < item.contact_person.personal_contact_methods.length; i++) {
      let object = item.contact_person.personal_contact_methods[i];
      if (type === object.medium) {
        return object.value;
      }
    }
  };


  const getContactInfo = (type) => {
    let values = []
    for (let i = 0; i < data.contact_info.length; i++) {
      let item = data.contact_info[i]
      if (item.contact_method.medium === type) {
        values.push(item)
      }

    }
    if (type === 'Email' && values.length === 0 && data.personal_email && data.personal_email !== "") {
      values = [
        {
          "score": 10,
          "datasource": "CUSTOMER",
          "contact_method": {
            "medium": "Email",
            "value": "",
            "name": "",
            "new_value": ""
          }
        }
      ]
    }

    const priority = [
      "CUSTOMER",
      "OSN",
      "KBO",
    ]

    let new_values = values.sort((a, b) => {
      if (priority.indexOf(a.datasource) > priority.indexOf(b.datasource)) return 1;
      if (priority.indexOf(a.datasource) < priority.indexOf(b.datasource)) return -1;
    })
    if (new_values.length > 0) { return new_values[0].contact_method.value }
    else { return "" }

  };




  const changeVersion = (type) => {
    let copy = data;
    copy.paid_version = type;

    if (type === false) {
      for (let i = 0; i < copy.extra_categories.length; i++) {
        copy.extra_categories[i].active = false;
        copy.extra_categories.length = 1;
        copy.extra_categories[0].name = "";
      }
      for (let v = 0; v < copy.extra_categories.length; v++) {
        copy.extra_categories[v].active = false;
      }
    }

    if (type === true) {
      for (let k = 0; k < copy.kbo_extra_categories.length; k++) {
        copy.kbo_extra_categories[k].active = true;
      }
      for (let j = 0; j < copy.extra_categories.length; j++) {
        copy.extra_categories[j].active = true;
      }
    }

    setData(copy);
    setLoaded(false);
  };

  useEffect(() => {
    isMounted.current = true;

    setData({
      ...props.data,
      contact_info: props.data.contact_info || {}
    });
    setLoaded(true);

    return () => {
      isMounted.current = false;
      source.cancel("Component unmounted");
    };
  }, [props.data]); // Add dependency array to avoid infinite loops


  return (
    <section className="c">
      <div className="intro">
        <h2>
          <span className="typo">03</span>
        </h2>
        <div>
          <h2>{translate('how_can_visitors_reacth_you')}</h2>
          <span className="intro-text">
            {translate('you_understand_importance')}
            <br /> {translate('visitors_reacht_you')}
            <br />
            <span className="extra">
              <Icon name="Info" />
              {translate('the_following_information')}&nbsp;<b>{translate('visible_to_everyone')}</b>
            </span>
          </span>
        </div>
      </div>
      {data && (
        <div className="category">
          <div className="section-container">
            <form className="section">
              <div className="public-inputs">
                <div>
                  <OsnInputText title={translate('corporate_name')} icon="Briefcase" required defaultValue={data[`official_name_${lang}`] || data.official_name_nl || data.official_name_fr || data.official_name_de || data.official_name_en} className="input" disabled />
                </div>
                <label className="commercieleNaam">
                  <span ref={publicNameRef}>
                    <OsnInputText title={translate('commercial_name')} icon="Briefcase" required defaultValue={data[`commercial_name_${lang}`] || data.commercial_name_nl || data.commercial_name_fr || data.commercial_name_de || data.commercial_name_en} className="input" />
                  </span>

                  <div className="info">
                    {translate('commercial_name_info')}
                    {/* <p>Indien uw "Commerciële naam" verschilt van uw "Officiële naam (ook Maatschappelijke naam genoemd)" kan je deze hier invoeren.</p> */}
                    {/* <p>Wij respecteren alle leestekens zoals je deze hier zelf invoert. Gebruik dus HOOFDLETTERS en kleine letters waar nodig!</p> */}
                  </div>
                </label>
                <div>
                  <span ref={publicEmailRef}>
                    <OsnInputText title={translate('email_never_visible')} icon="Mail" required defaultValue={getContactInfo('Email')} onBlur={(e) => changeHyperlink(e.target.value, "email")} className="input" />
                  </span>
                </div>
                <br />
                <span className="help">
                  <Icon name="Info" />
                  <p>
                    {translate('publication_of_one_telephone')}&nbsp;
                    <a href={lang === 'nl' ? "https://news.economie.fgov.be/203683-deze-info-moet-u-zeker-vermelden-op-uw-bedrijfswebsite" : "https://news.economie.fgov.be/203681-informations-obligatoires-sur-le-site-web-de-votre-entreprise"} target="_blank" rel="noreferrer" className="sub bold">
                      {translate('requirement')}
                    </a>
                    &nbsp;{translate('of_FPS_economy')}
                  </p>
                </span>
                <div>
                  <span ref={publicCellphoneRef}>
                    <OsnInputText title={translate('ci_mobile')} icon="Cellphone" required defaultValue={getContactInfo('Mobile')} onBlur={(e) => changeHyperlink(e.target.value, "mobile")} className="input" />
                  </span>
                </div>

                <span ref={publicPhoneRef}>
                  <OsnInputText title={translate('ci_phone')} icon="Phone" required defaultValue={getContactInfo('Fixed')} onBlur={(e) => changeHyperlink(e.target.value, "fixed")} className="input" />
                </span>

                <span ref={publicFaxRef}>
                  <OsnInputText title={translate('ci_fax')} icon="Fax" required defaultValue={getContactInfo('Fax')} className="input" onBlur={(e) => changeHyperlink(e.target.value, "fax")} />
                </span>
                <label className="select">
                  {translate('do_you_trade_companies_or_consumers')}
                  <OsnSelect
                    onChange={(e) => {
                      let copy = data;

                      copy.trade_with_consumers = e.id;

                      setData(copy);
                      setLoaded(false);
                    }}
                    active={data.trade_with_consumers === 0 ? translate('please_choose_an_option') : data.trade_with_consumers === 3 ? translate('i_trade_with_b2b') : data.trade_with_consumers === 2 ? translate('i_trade_with_b2c') : translate('i_trade_with_both')}
                    options={[
                      {
                        id: 0,
                        name: translate('please_choose_an_option'),
                      },
                      {
                        id: 3,
                        name: translate('i_trade_with_b2b'),
                      },
                      {
                        id: 2,
                        name: translate('i_trade_with_b2c'),
                      },
                      {
                        id: 1,
                        name: translate('i_trade_with_both'),
                      },
                    ]}
                  />
                </label>
                <label className="select">
                  {translate('what_do_you_offer')}

                  <OsnSelect
                    onChange={(e) => {
                      let copy = data;
                      copy.offering = e.id;
                      setData(copy);
                      setLoaded(false);
                    }}
                    active={data.offering === 0 ? translate('please_choose_an_option') : data.offering === 1 ? translate('i_offer_both') : data.offering === 2 ? translate('i_offer_goods') : translate('i_offer_services')}
                    options={[
                      {
                        id: 0,
                        name: translate('please_choose_an_option'),
                      },
                      {
                        id: 1,
                        name: translate('i_offer_both'),
                      },
                      {
                        id: 2,
                        name: translate('i_offer_goods'),
                      },
                      {
                        id: 3,
                        name: translate('i_offer_services'),
                      },
                    ]}
                  />
                </label>
              </div>
            </form>

            <div className="section">
              {!data.paid_version ?
                <p>{translate('you_company_is_now_found_on_your')} <b className="yellow">{translate('main_activity')}</b></p>
                :
                <p>{translate('you_company_is_now_found_on')} <b className="yellow">{translate('all_activities')}</b> {translate('that_you_fill_in')}</p>
              }
              {/* {data.paid_version && <b className="yellow"> activiteit(en)</b>}
                {!data.paid_version && <b className="yellow"> activiteit</b>}: */}
              {/* <div className={[!data.paid_version ? "active" : "", "false-info"].join(" ")}>
                  <Icon name="" />
                  <p> {translate('choice_for_paying')} <b className="yellow">"{translate('commit_you_to_nothing')}</b> De keuze om jouw onderneming aan meerdere activiteiten te koppelen geeft uitsluitend aan dat je een vrijblijvend bezoek van onze raadgever vraagt. Tijdens dit bezoek krijg je alle informatie en kan je nog steeds terugschakelen naar de keuze voor "GRATIS" op basis van jouw hoofdactiviteit.</p>
                </div> */}
              <div
                onClick={() => {
                  changeVersion(!data.paid_version);
                }}
                className={["option2", data.paid_version ? "active" : ""].join(" ")}
              >
                <p className="typo">{translate('paying')}</p>
                <p className="typo">{translate('free')}</p>
              </div>
              {!data.paid_version && (
                <div className={[!data.paid_version ? "active" : "", "false-info"].join(" ")}>
                  <Icon name="Info" />
                  <p> {translate('choice_for_paying')} <b className="yellow">{translate('commit_you_to_nothing')}</b> {translate('the_choice_to_link_your_company')}</p>
                </div>

              )}
              {data.paid_version && (

                <div className={[!data.paid_version ? "active" : "", "false-info"].join(" ")}>
                  <Icon name="Info" />
                  <p> {translate('choice_for_paying')} <b className="yellow">{translate('commit_you_to_nothing')}</b> {translate('the_choice_to_link_your_company')}</p>
                </div>
              )}
              <section>
                <h1 className="typo">{translate('your_main_activity')}</h1>
                <div className="c-selected__list">
                  <button className={["select-button", data.main_customer_category.category === undefined ? "active" : "inactive"].join(" ")}>
                    <p
                      onClick={(e) => {
                        changeCategory(e, "KBOMAINACTIVE");
                      }}
                    >
                      {data.kbo_main_category.category[`name_${lang}`] || data.kbo_main_category.category.name_nl || data.kbo_main_category.category.name_fr || data.kbo_main_category.category.name_de || data.kbo_main_category.category.name_en}
                    </p>
                  </button>
                  {data.main_customer_category.category && (
                    <button className="select-button active">
                      <Icon
                        className="c-selected-icon"
                        onClick={(e) => {
                          changeCategory(
                            e,
                            "REMOVEMAIN",
                            data.main_customer_category.category.name_nl

                          );
                        }}
                        name="CloseCircle"
                      />
                      {data.main_customer_category.category[`name_${lang}`] || data.main_customer_category.category.name_nl || data.main_customer_category.category.name_fr || data.main_customer_category.category.name_de || data.main_customer_category.category.name_en}
                    </button>
                  )}
                </div>
                <span className="search-bar" ref={searchMainRef}>
                  <OsnInputText
                    dropdown_result={dropdownloaded ? [...mainResult] : []}
                    disabled={data.main_customer_category.cateogry === undefined ? false : true}
                    placeholder={data.main_customer_category.cateogry === undefined ? translate('wish_to_modify_your_activity') : translate('already_modified_your_activity')}
                    onMouseDown={(e) => {
                      changeCategory(e, "ADDMAIN");
                      searchMainRef.current.childNodes[0].childNodes[0].value = ""
                    }}
                    onChange={(e) => {
                      getSearchdata(searchMainRef.current.childNodes[0].childNodes[0].value, "main");
                    }}
                  />
                </span>
                {([62, 63, 65].includes(Number(data.main_customer_category.code)) || [62, 63, 65].includes(Number(data.kbo_main_category.code))) && (
                  <label>
                    <div>
                      <span ref={ITAARef}>
                        <OsnInputText required title={translate('itaa_registration')} onChange={(e) => setItaa(e.target.value)} defaultValue={itaa} value={itaa || ''} icon="Fingerprint" />
                      </span>
                    </div>
                  </label>
                )}
                {([1945, 2349, 3778].includes(Number(data.main_customer_category.code)) || [1945, 2349, 3778].includes(Number(data.kbo_main_category.code))) && (
                  <span >
                    <OsnInputText required title={translate('apb_registration')} max="5" type="number" onChange={(e) => changeHyperlink(e.target.value, "APB")} defaultValue={getContactInfo('APB')} icon="Pharmacy" />
                  </span>
                )}
                {([300, 3201, 3205, 3209, 3208, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 5269].includes(Number(data.main_customer_category.code)) || [300, 3201, 3205, 3209, 3208, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 5269].includes(Number(data.kbo_main_category.code)))
                  &&
                  <label>
                    <div>
                      <span ref={FMSARef}>
                        <OsnInputText required title={translate('fsma_registration')} onChange={(e) => setFsma(e.target.value)} defaultValue={fsma} value={fsma || ''} icon="Fingerprint" />
                      </span>
                    </div>
                  </label>
                }

                {([3246, 3250, 3251, 3252, 3263].includes(Number(data.main_customer_category.code)) || [3246, 3250, 3251, 3252, 3263].includes(Number(data.kbo_main_category.code))) && (
                  <Fragment>
                    {data.contact_persons.map((value, index) => (
                      <div key={index} className={["biv-row", data.contact_persons.length - 1 === index ? "last" : ""].join(" ")}>
                        <div>
                          <label>
                            <div>
                              <OsnInputText size="M" required title={translate('biv_registration')} defaultValue={value.contact_person.biv} icon="Biv" onBlur={(e) => changeContactPerson(index, "biv", e.target.value, "BIV")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "BIV")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "BIV")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText required size="M" icon="Cellphone" title={translate('ci_mobile')} defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "BIV")} />
                            </div>
                          </label>
                          <label className="email">
                            <div>
                              <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "BIV")} />
                            </div>
                          </label>
                        </div>
                        <div className="buttons">
                          {data.contact_persons.length !== 1 && (
                            <label onClick={(e) => RemoveContactPerson(index, "BIV")}>
                              <Icon name="CloseCircle" />
                            </label>
                          )}
                          {data.contact_persons.length === index + 1 && (
                            <label onClick={(e) => addContactPerson(e, "BIV")}>
                              <Icon name="AddCircle" />
                            </label>
                          )}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                )}
                {([3264, 3267, 3268, 3270].includes(Number(data.main_customer_category.code)) || [3264, 3267, 3268, 3270].includes(Number(data.kbo_main_category.code))) && (
                  <Fragment>
                    {data.contact_persons.map((value, index) => (
                      <div key={index} className={["biv-row", data.contact_persons.length - 1 === index ? "last" : ""].join(" ")}>
                        <div>
                          <label>
                            <div>
                              <OsnInputText size="M" required title={translate('epc_registration')} defaultValue={value.contact_person.epc} icon="Biv" onBlur={(e) => changeContactPerson(index, "epc", e.target.value, "EPC")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "EPC")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "EPC")} />
                            </div>
                          </label>
                          <label>
                            <div>
                              <OsnInputText required size="M" icon="Cellphone" title={translate('ci_mobile')} defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "EPC")} />
                            </div>
                          </label>
                          <label className="email">
                            <div>
                              <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "EPC")} />
                            </div>
                          </label>
                        </div>
                        <div className="buttons">
                          {data.contact_persons.length !== 1 && (
                            <label onClick={(e) => RemoveContactPerson(index, "EPC")}>
                              <Icon name="CloseCircle" />
                            </label>
                          )}
                          {data.contact_persons.length === index + 1 && (
                            <label onClick={(e) => addContactPerson(e, "EPC")}>
                              <Icon name="AddCircle" />
                            </label>
                          )}
                        </div>
                      </div>
                    ))}
                  </Fragment>
                )}
              </section>

              {data.paid_version && (
                <section>
                  <h1 className="typo">{translate('your_activities_retrieved_from_CBE')}</h1>
                  {data.paid_version !== "" && (
                    <Fragment>
                      <div className="c-selected__list">
                        {data.kbo_extra_categories.map((item, index) => (
                          <Fragment key={index}>
                            <button className={["select-button", item.active ? "active" : ""].join(" ")}>
                              <Icon
                                className="c-selected-icon"
                                onClick={(e) => {
                                  changeCategory(e, "KBOSUBACTIVE", index);
                                }}
                                name={[item.active ? "Checked" : "Unchecked"]}
                              />
                              <p
                                onClick={(e) => {
                                  changeCategory(e, "KBOSUBACTIVE", index);
                                }}
                              >
                                {item.category[`name_${lang}`] || item.category.name_nl || item.category.name_fr || item.category.name_de || item.category.name_en}
                              </p>
                            </button>
                          </Fragment>
                        ))}
                      </div>

                      {data.kbo_extra_categories.some((item) => ([62, 63, 65].includes(Number(item.code)) && item.active)) && (
                        <label>
                          <div>
                            <span ref={ITAARef}>
                              <OsnInputText required title={translate('itaa_registration')} onChange={(e) => setItaa(e.target.value)} defaultValue={itaa} value={itaa} icon="Fingerprint" />
                            </span>
                          </div>
                        </label>
                      )}
                      {data.kbo_extra_categories.some((item) => ([1945, 2349, 3778].includes(Number(item.code)) && item.active)) && (
                        <span >
                          <OsnInputText required title={translate('apb_registration')} onChange={(e) => changeHyperlink(e.target.value, "APB")} defaultValue={getContactInfo('APB')} icon="Pharmacy" />
                        </span>
                      )}
                      {data.kbo_extra_categories.some((item) => ([300, 3201, 3205, 3209, 3208, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 5269].includes(Number(item.code)) && item.active)) && (
                        <label>
                          <div>
                            <span ref={FMSARef}>
                              <OsnInputText required title={translate('fsma_registration')} onChange={(e) => setFsma(e.target.value)} defaultValue={fsma} value={fsma} icon="Fingerprint" />
                            </span>
                          </div>
                        </label>
                      )}
                      {data.kbo_extra_categories.some((item) => ([3246, 3250, 3251, 3252, 3263].includes(Number(item.code)) && item.active)) && (
                        <Fragment>
                          {data.contact_persons.map((value, index) => (
                            <div key={index} className={["biv-row", BIV.length - 1 === index ? "last" : ""].join(" ")}>
                              <div>
                                <label>
                                  <div>
                                    <OsnInputText size="M" required title={translate('biv_registration')} defaultValue={value.contact_person.biv} icon="Biv" onBlur={(e) => changeContactPerson(index, "biv", e.target.value, "BIV")} />
                                  </div>
                                </label>
                                <label>
                                  <div>
                                    <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "BIV")} />
                                  </div>
                                </label>
                                <label>
                                  <div>
                                    <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "BIV")} />
                                  </div>
                                </label>
                                <label>
                                  <div>
                                    <OsnInputText required size="M" icon="Cellphone" title="mobiele telefoon" defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "BIV")} />
                                  </div>
                                </label>
                                <label className="email">
                                  <div>
                                    <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "BIV")} />
                                  </div>
                                </label>
                              </div>
                              <div className="buttons">
                                {BIV.length !== 1 && (
                                  <label onClick={(e) => RemoveContactPerson(index, "BIV")}>
                                    <Icon name="CloseCircle" />
                                  </label>
                                )}
                                {data.contact_persons.length === index + 1 && (
                                  <label onClick={(e) => addContactPerson(e, "BIV")}>
                                    <Icon name="AddCircle" />
                                  </label>
                                )}
                              </div>
                            </div>
                          ))}
                        </Fragment>
                      )}
                      {data.kbo_extra_categories.some((item) => ([3264, 3267, 3268, 3270].includes(Number(item.code)) && item.active)) && (
                        <Fragment>
                          {data.contact_persons.map((value, index) => (
                            <div key={index} className={["biv-row", EPC.length - 1 === index ? "last" : ""].join(" ")}>
                              <div>
                                <label>
                                  <div>
                                    <OsnInputText size="M" required title={translate('epc_registration')} defaultValue={value.contact_person.epc} icon="Biv" onBlur={(e) => changeContactPerson(index, "epc", e.target.value, "EPC")} />
                                  </div>
                                </label>
                                <label>
                                  <div>
                                    <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "EPC")} />
                                  </div>
                                </label>
                                <label>
                                  <div>
                                    <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "EPC")} />
                                  </div>
                                </label>
                                <label>
                                  {value.contact_person.personal_contact_methods.some((item) => {
                                    if (item.medium === " Mobile") {
                                      return item.value;
                                    }
                                  })}
                                  <div>
                                    <OsnInputText required size="M" icon="Cellphone" title={translate('ci_mobile')} defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "EPC")} />
                                  </div>
                                </label>
                                <label className="email">
                                  <div>
                                    <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "EPC")} />
                                  </div>
                                </label>
                              </div>
                              <div className="buttons">
                                {EPC.length !== 1 && (
                                  <label onClick={(e) => RemoveContactPerson(index, "EPC")}>
                                    <Icon name="CloseCircle" />
                                  </label>
                                )}
                                {data.contact_persons.length === index + 1 && (
                                  <label onClick={(e) => addContactPerson(e, "EPC")}>
                                    <Icon name="AddCircle" />
                                  </label>
                                )}
                              </div>
                            </div>
                          ))}
                        </Fragment>
                      )}

                      <br />
                      {data.extra_categories && data.extra_categories.length > 0 && (
                        <Fragment>
                          <p>{translate('your_choice_for_extra_activities')}</p>
                          <div className="c-selected__list">

                            {/* {data.extra_categories.length !== 0 && data.extra_categories[0].name_nl.length !== 0 && ( */}
                            <Fragment>
                              {data.extra_categories.map((category, index) => (
                                <Fragment key={index}>
                                  <button className={["select-button", category.active ? "active" : ""].join(" ")}>
                                    <Icon
                                      className="c-selected-icon"
                                      onClick={(e) => {
                                        changeCategory(e, "REMOVE", index, category);
                                      }}
                                      name="CloseCircle"
                                    />
                                    <p
                                      onClick={(e) => {
                                        changeCategory(e, "ACTIVE", index);
                                      }}
                                    >
                                      {category.category[`name_${lang}`] || category.category.name_nl || category.category.name_fr || category.category.name_de || category.category.name_en}
                                    </p>
                                  </button>
                                </Fragment>
                              ))}
                            </Fragment>
                            {/* )} */}
                            {/* {data.extra_categories.length === 0 || (data.extra_categories[0].name_nl.length === 0 && <br />)} */}
                          </div>
                        </Fragment>
                      )}
                      <p>{translate('here_you_can_add_multiple_activities')}</p>
                      <span className="search-bar" ref={searchRef}>
                        <OsnInputText
                          dropdown_result={dropdownloaded ? [...result] : []}
                          required
                          placeholder={translate('do_you_wish_to_modify_or_add_an_activity')}
                          onMouseDown={(e) => {
                            changeCategory(e, "ADD");
                            searchRef.current.childNodes[0].childNodes[0].value = ""
                          }}
                          onChange={(e) => {
                            getSearchdata(searchRef.current.childNodes[0].childNodes[0].value, "sub");
                          }}
                        />
                      </span>
                    </Fragment>
                  )}
                  {/* {([62, 63, 65].includes(Number(data.main_customer_category.code)) || [62, 63, 65].includes(Number(data.kbo_main_category.code))) && ( */}
                  {data.extra_categories.some((item) => ([62, 63, 65].includes(Number(item.code)) && item.active)) && (
                    <label>
                      <div>
                        <span ref={ITAARef}>
                          <OsnInputText required title={translate('itaa_registration')} onChange={(e) => setItaa(e.target.value)} defaultValue={itaa} value={itaa} icon="Fingerprint" />
                        </span>
                      </div>
                    </label>
                  )}
                  {data.extra_categories.some((item) => ([1945, 2349, 3778].includes(Number(item.code)) && item.active)) && (
                    <span >
                      <OsnInputText required title={translate('apb_registration')} onChange={(e) => changeHyperlink(e.target.value, "APB")} defaultValue={getContactInfo('APB')} icon="Pharmacy" />
                    </span>
                  )}
                  {data.extra_categories.some((item) => ([300, 3201, 3205, 3209, 3208, 3220, 3221, 3222, 3223, 3224, 3225, 3226, 3227, 3228, 3229, 3230, 3231, 3232, 3233, 3234, 3235, 3236, 3237, 5269].includes(Number(item.code)) && item.active)) && (
                    <label>
                      <div>
                        <span ref={FMSARef}>
                          <OsnInputText required title={translate('fsma_registration')} onChange={(e) => setFsma(e.target.value)} defaultValue={fsma} value={fsma} icon="Fingerprint" />
                        </span>
                      </div>
                    </label>
                  )}
                  {data.extra_categories.some((item) => ([3246, 3250, 3251, 3252, 3263].includes(Number(item.code)) && item.active)) && (
                    <Fragment>
                      {data.contact_persons.map((value, index) => (
                        <div key={index} className={["biv-row", BIV.length - 1 === index ? "last" : ""].join(" ")}>
                          <div>
                            <label>
                              <div>
                                <OsnInputText size="M" required title={translate('biv_registration')} defaultValue={value.contact_person.biv} icon="Biv" onBlur={(e) => changeContactPerson(index, "biv", e.target.value, "BIV")} />
                              </div>
                            </label>
                            <label>
                              <div>
                                <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "BIV")} />
                              </div>
                            </label>
                            <label>
                              <div>
                                <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "BIV")} />
                              </div>
                            </label>
                            <label>
                              <div>
                                <OsnInputText required size="M" icon="Cellphone" title="mobiele telefoon" defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "BIV")} />
                              </div>
                            </label>
                            <label className="email">
                              <div>
                                <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "BIV")} />
                              </div>
                            </label>
                          </div>
                          <div className="buttons">
                            {BIV.length !== 1 && (
                              <label onClick={(e) => RemoveContactPerson(index, "BIV")}>
                                <Icon name="CloseCircle" />
                              </label>
                            )}
                            {data.contact_persons.length === index + 1 && (
                              <label onClick={(e) => addContactPerson(e, "BIV")}>
                                <Icon name="AddCircle" />
                              </label>
                            )}
                          </div>
                        </div>
                      ))}
                      {data.contact_persons.length === 0 && (
                        <div className="buttons">
                          <label onClick={(e) => addContactPerson(e, "EPC")}>
                            <Icon name="AddCircle" />
                          </label>
                        </div>
                      )}
                    </Fragment>
                  )}
                  {data.extra_categories.some((item) => ([3264, 3267, 3268, 3270].includes(Number(item.code)) && item.active)) && (
                    <Fragment>
                      {loaded && data.contact_persons.map((value, index) => (
                        <div key={index} className={["biv-row", (data.contact_persons.length - 1) === index ? "last" : ""].join(" ")}>
                          <div>
                            <label>
                              <div>
                                <OsnInputText size="M" required title={translate('epc_registration')} defaultValue={value.contact_person.epc} icon="Biv" onBlur={(e) => changeContactPerson(index, "epc", e.target.value, "EPC")} />
                              </div>
                            </label>
                            <label>
                              <div>
                                <OsnInputText size="M" required icon="UserM" title={translate('ci_prename')} defaultValue={value.contact_person.first_name} onBlur={(e) => changeContactPerson(index, "prename", e.target.value, "EPC")} />
                              </div>
                            </label>
                            <label>
                              <div>
                                <OsnInputText size="M" icon="UserM" required title={translate('ci_name')} defaultValue={value.contact_person.last_name} onBlur={(e) => changeContactPerson(index, "name", e.target.value, "EPC")} />
                              </div>
                            </label>
                            <label>
                              {value.contact_person.personal_contact_methods.some((item) => {
                                if (item.medium === " Mobile") {
                                  return item.value;
                                }
                              })}
                              <div>
                                <OsnInputText required size="M" icon="Cellphone" title={translate('ci_mobile')} defaultValue={getContactData(value, "Mobile")} onBlur={(e) => changeContactPerson(index, "mobile", e.target.value, "EPC")} />
                              </div>
                            </label>
                            <label className="email">
                              <div>
                                <OsnInputText required size="L" icon="Mail" title={translate('email_never_visible')} defaultValue={getContactData(value, "Email")} onBlur={(e) => changeContactPerson(index, "email", e.target.value, "EPC")} />
                              </div>
                            </label>
                          </div>
                          <div className="buttons">
                            <label onClick={(e) => RemoveContactPerson(index, "EPC")}>
                              <Icon name="CloseCircle" />
                            </label>
                            {data.contact_persons.length === index + 1 && (

                              <label onClick={(e) => addContactPerson(e, "EPC")}>
                                <Icon name="AddCircle" />
                              </label>
                            )}
                          </div>
                        </div>
                      ))}
                      {data.contact_persons.length === 0 && (
                        <div className="buttons">
                          <label onClick={(e) => addContactPerson(e, "EPC")}>
                            <Icon name="AddCircle" />
                          </label>
                        </div>
                      )}
                    </Fragment>
                  )}
                </section>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="button-container">
        <Button
          text={translate('previous')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            store.dispatch({ type: "countDown" });
            props.setTab(1);
          }}
        />
        <Button
          text={translate('next')}
          type="sub"
          size="S"
          brderColor="main"
          onClick={(e) => {
            // if (data.contact_info.length > 0 && !data.contact_info.filter(cm => cm.contact_method.medium === 'Email')[0].contact_method.value && !data.personal_email) {
            //   props.createNotification("warning", "Gelieve een email in te vullen.")
            // // if (!(data.contact_info || []).data.contact_info.filter(cm => cm.contact_method.medium === 'Email')[0].contact_method.value && !data.personal_email) {
            // //   props.createNotification("warning", "Gelieve een email in te vullen.")
            // }

            // if (!data.contact_info?.contact_method || data.contact_info.contact_method.medium !== 'Email' ||
            //   !data.contact_info.contact_method.value?.trim() && !data.personal_email?.trim()
            // )

            const emailFromContactInfo = data.contact_info?.contact_method?.medium === 'Email'
              ? data.contact_info?.contact_method?.value?.trim()
              : null;

            const personalEmail = data.personal_email?.trim();

            if (!emailFromContactInfo && !personalEmail) {
              props.createNotification("warning", "Gelieve een email in te vullen.");
            } else {
              changePublic(e);
              patchData(data, 3);
            }
          }}
        />
      </div>
    </section>
  );
};

export default Search;
