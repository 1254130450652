import React, { useEffect, useState } from 'react';
import OrdersPolygonMap from './orders/OrdersPolygonMap';

const PolygonTest = () => {
    const [type, setType] = useState('');
    const [subscriptionTypeNumber, setSubscriptionTypeNumber] = useState('');
    const [subMunicipalitySlug, setSubMunicipalitySlug] = useState('');
    const [subscribedMunicipalities, setSubscribedMunicipalities] = useState('');
    const [excludedMunicipalities, setExcludedMunicipalities] = useState('');
    const [subscribedProvinces, setSubscribedProvinces] = useState('');
    const [subscribedRegions, setSubscribedRegions] = useState('');

    return (
        // give select input for type: local, level, province, region, country
        // give input for subscription type number
        <div>
            <h1>Polygon Test</h1>
            <label>
                Type:
                <select value={type} onChange={(e) => setType(e.target.value)}>
                    <option value="">Select Type</option>
                    <option value="local">Local</option>
                    <option value="level">Level</option>
                    <option value="province">Province</option>
                    <option value="region">Region</option>
                    <option value="country">Country</option>
                </select>
            </label>
            <br />
            <label>
                Subscription Type Number:
                <input
                    type="number"
                    value={subscriptionTypeNumber}
                    onChange={(e) => setSubscriptionTypeNumber(e.target.value)}
                />
            </label>
            <br />
            <label>
                Current Sub Municipality ID:
                <input
                    type="text"
                    value={subMunicipalitySlug}
                    onChange={(e) => setSubMunicipalitySlug(e.target.value)}
                />
            </label>
            <br />
            <label>
                Subscribed Municipalities:
                <input
                    type="text"
                    value={subscribedMunicipalities}
                    onChange={(e) => setSubscribedMunicipalities(e.target.value)}
                />
            </label>
            <br />
            <label>
                Excluded Municipalities:
                <input
                    type="text"
                    value={excludedMunicipalities}
                    onChange={(e) => setExcludedMunicipalities(e.target.value)}
                />
            </label>
            <br />
            <label>
                Subscribed Provinces:
                <input
                    type="text"
                    value={subscribedProvinces}
                    onChange={(e) => setSubscribedProvinces(e.target.value)}
                />
            </label>
            <br />
            <label>
                Subscribed Regions:
                <input
                    type="text"
                    value={subscribedRegions}
                    onChange={(e) => setSubscribedRegions(e.target.value)}
                />
            </label>
            <br />

            {/* Render the OrdersPolygonMap component and pass the necessary props */}
            <OrdersPolygonMap
                currentSubmunicipalitySlug={subMunicipalitySlug}
                subscriptionType={`${type}_${subscriptionTypeNumber}`}
                subscribedMunicipalities={subscribedMunicipalities.split(',')}
                excludedMunicipalities={excludedMunicipalities.split(',')}
                subscribedProvinces={subscribedProvinces.split(',')}
                subscribedRegions={subscribedRegions.split(',')}
            />



        </div>

    );
}

export default PolygonTest;