const ReverseTimetableModel = (json, lang) => {
    let data = []
    // Loop through timetables
    json.map((res) => {
        // New empty item
        let item = {
            "score": 1,
            "timetable": {
                "timetable_type": {
                    "title": res.name
                },
                "appointment_only": false,
                "is_24_7": res.is_24_7,
                "records": [],
                "messages": [],
                "period": [],
                "day_exception": [],
                "id": res.id ? res.id : ""
            }
        }
        if(res.message){ item.timetable.messages.push({"message": res.message, "title": ""})}
        res.exceptions.map((exception) => {
          let slots = []
          !exception.closed && exception.slots.map((slot) => {
            slots.push({
              "from_time": slot.from,
              "to_time": slot.until,
              "message": slot.messageText
            })
          })
          item.timetable.day_exception.push({
            "date": exception.date,
            "holliday": exception.holliday,
            "message": exception.message,
            "closed": exception.closed,
            "exception_timeslots": slots
          })
        })

        res.periode_list.map(period => {
          item.timetable.period.push({
            "start": period.periodeFrom,
            "end": period.periodeUntil,
            "reason": period.periodeMessage
          })
        })

        Object.entries(res.table).map(([key, value], index) => {
            let new_record = {
                "appointment": false,
                "closed": false,
                "valid_days": [],
                "timeslots": [],
            }
            let afwijkend = false
            let closed = false
            let hasHours = false
            let day = (index + 1).toString()
            if(key === 'global'){ day = '0'}  
            value.map((slot) => {
                if (key !== 'global' && slot.closed === true) { closed = true }
                    afwijkend = true
                    if(!new_record.valid_days.includes(day)){new_record.valid_days.push(day)}
                    if (slot.from !== "" && slot.until != ""){
                        hasHours = true
                        let new_slot = {
                            "from_time": slot.from,
                            "to_time": slot.until,
                            "message": null
                        }
                        if (closed === false){new_record.timeslots.push(new_slot)}
                        if(slot.messageText !== "") { new_slot.message = slot.messageText}
                    }
            })

            new_record.closed = closed
            if(afwijkend === true){item.timetable.records.push(new_record)}
            
        })

        // Combine records that have the same values
        const fun = (ar)=>{
            const output = ar.reduce((prev, curr) => {
             const tmp = prev.find(e => JSON.stringify(e.timeslots)==JSON.stringify(curr.timeslots) && JSON.stringify(e.closed)==JSON.stringify(curr.closed));
               if (tmp) {
                 tmp.valid_days.push(...curr.valid_days);
               } else {
                 prev.push({
                   timeslots: curr.timeslots,
                   valid_days: [...curr.valid_days],
                   closed: curr.closed,
                   appointment: curr.appointment,
                 });
               }
               return prev;
             }, []);
             return output
           }

            // change the records to the new combined records    
           item.timetable.records = fun(item.timetable.records.reverse())
          
           // Remove items without timeslots
           let newItems = []
           item.timetable.records.map((res) => (res.timeslots.length > 0 || res.closed === true) && newItems.push(res))
           item.timetable.records = newItems

        data.push(item)
    })

    return data;
  };
  
  export default ReverseTimetableModel;
  