export const updateLang = (lang) => ({
  type: "UPDATE_LANG",
  lang,
});

export const updateCurrentEstablishment = (establishment) => ({
  type: "UPDATE_CURRENT_ESTABLISHMENT",
  establishment,
});

export const updateCorrectOTP = (value) => ({
  type: "UPDATE_CORRECT_OTP",
  value,
});

export const updateEmail = (value) => ({
  type: "UPDATE_EMAIL",
  value,
});

export const updatePassword = (value) => ({
  type: "UPDATE_PASSWORD",
  value,
});

export const updateNav = (value) => ({
  type: "UPDATE_NAV",
  value,
});
export const openContact = (value) => ({
  type: "OPEN_CONTACT",
  value,
});
export const openOrder = (value) => ({
  type: "OPEN_ORDER",
  value,
});
export const setIsAdmin = (value) => ({
  type: "SET_IS_ADMIN",
  value,
});
